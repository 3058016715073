.table {
    border-spacing: 2px 0;

    & [data-pc-section='wrapper'] {
        scrollbar-width: thin;
        scrollbar-gutter: stable;
        scroll-padding-top: 46px;
    }
}

.tableBody {
    tr:hover {
        td {
            background-color: rgba(47, 156, 235, 0.2);
        }

        td:last-child {
            background-color: transparent;
        }
    }
}

.tableHeader > tr > th {
    background: var(--ds-main-white);
    border-color: #636e80;
    padding: 0 18px 8px 18px;
    margin: 0 2px 2px 0;

    &:nth-child(1) {
        padding-top: 20px;
        min-width: 140px;
        max-width: 140px;
        width: 140px;
    }

    &:nth-child(2) {
        padding-top: 5px;
        min-width: 158px;
        max-width: 164px;
        width: 164px;

        span svg {
            position: relative;
            top: 14px;
        }
    }

    &:nth-child(3) {
        padding: 10px 0 0;
        min-width: 90px;
    }

    &:last-child {
        display: none;
        padding: 0;
        margin: 0;
        width: 0;
    }

    & > div {
        gap: 2px;

        &:first-child {
            justify-content: space-between;
            align-items: stretch;
        }
    }

    span {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 16px;
        text-align: left;
        color: var(--ds-dtsip-text-light-25);
    }

    &:not(:first-child) span {
        text-align: right;
    }
}

[aria-sort='descending'] .sortIcon {
    & > path:last-child {
        stroke: var(--ds-bg-dark-blue);
    }
}

[aria-sort='ascending'] .sortIcon {
    & > path:first-child {
        stroke: var(--ds-bg-dark-blue);
    }
}

.tableRow {
    cursor: pointer;

    &:hover > td:last-child > button {
        display: flex;
    }

    &:nth-of-type(even) {
        background: var(--ds-main-white);

        td {
            background: var(--ds-main-white);
        }
    }

    &:nth-of-type(odd) {
        background: #f7fafd;

        td {
            background: #f7fafd;
        }
    }

    & > td {
        margin: 0 2px 2px 0;
        border-color: #d8e1eb;
        padding-left: 18px;

        &:last-child {
            background: var(--ds-main-white);
            border: none;
            max-width: 10px;
            min-width: 10px;
            width: 10px;
            position: relative;
            padding: 0;
        }
    }

    &:nth-child(n) > td:nth-child(2),
    &:nth-child(n) > td:nth-child(3) {
        padding: 13px 18px;
    }
}

.currentRow {
    td {
        position: relative;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            background: #d7e6f5;
        }

        &:first-child:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background: var(--ds-bg-dark-blue);
        }
    }
}

.nameWrapper {
    &_tooltip {
        background-color: white;
        position: absolute;
        transform: translateX(-50%) translateY(-180px);
        border: 1px solid #d8e1eb;
        border-radius: 6px;
        padding: 8px 18px;
        left: 110px;
        top: 0;
        pointer-events: none;
        z-index: 10000;
        user-select: none;
        max-width: 400px;
        transition: opacity 0.2s;

        span {
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 350;
            color: #636e80 !important;
        }

        svg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, calc(100%));
        }
    }

    span {
        display: block;
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);
        text-overflow: ellipsis;
        max-width: 122px;
        overflow: hidden;
        white-space: nowrap;

        &:last-child {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            color: #636e80;
        }
    }
}

.tooltip_date {
    text-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 2px;

    span:last-child {
        font-family: Inter, sans-serif;
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
    }
}

.closeWrapper {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: var(--ds-dtsip-text-black-100);

    span {
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        color: #636e80;
    }
}

.paramWrapper {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;

    &__red {
        color: #e42821;
    }

    &__green {
        color: #288c14;
    }

    &__gray {
        color: var(--ds-dtsip-text-black-100);
    }
}

.noValue {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: var(--ds-dtsip-text-black-100);
}

.actionWrapper {
    display: none;
    background: transparent;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
}

.overlayPanelWrapper {
    cursor: pointer;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    padding: 19px;

    &::before,
    &::after {
        content: none;
    }
}

.contentWrapper {
    gap: 10px;
    width: 136px;

    & > span {
        font-family: FavoritPro, sans-serif;
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: #576273;
    }
}

.contextMenu {
    box-shadow: none;
    width: auto;

    &_icon {
        color: #2f9ceb;
    }

    &_label {
        margin-left: 10px;
        font-family: FavoritPro, sans-serif;
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: #576273;
    }

    &_item {
        border-radius: 6px;
        border: 1px solid #d8e1eb;
        overflow: hidden;
        width: 175px;
        height: 58px;
        padding: 19px 19px;
        cursor: pointer;
        background: var(--ds-main-white);

        div {
            background: var(--ds-main-white);
        }

        &:hover {
            background-color: var(--ds-main-white);
        }
    }
}
