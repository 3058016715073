@import '../../../../../../../shared/commonStyles/main-styles.scss';

.notEmptyFavoritesWrapper {
    height: 100%;

    [data-pc-section='root'] {
        @include scrollbar;
    }

    & > div:not(.favoritesListWrapper) {
        width: 100%;

        height: calc(100% - 157px);

        overflow-x: auto;

        &.isEmpty > div:last-child {
            margin-top: 242px;
        }
    }

    & > button {
        width: 402px;
    }
}

.favoritesListWrapper {
    width: 100%;
    gap: 8px;
    margin-top: 25px;

    & > button {
        width: 36px;
        min-width: 36px;
        height: 36px;
        border: none;
        background: #a5b1c0;
    }

    & > div {
        width: 100%;
        height: 36px;
        max-width: 361px;
        display: flex;
        align-items: center;
        padding: 10px 3px 10px 10px;
        box-sizing: border-box;
    }

    & span {
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
    }
}

.btn {
    min-height: 48px;
    border: none;
    border-radius: 6px;
    background-color: var(--ds-bg-dark-blue);
    gap: 4px;

    & > svg {
        width: 14px;
        height: 14px;
        margin: 5px;
    }

    & > span {
        flex: none;
        font-family: FavoritPro, sans-serif;
        font-size: 13px;
        font-weight: 350;
        line-height: 16px;
        text-align: center;
        color: var(--ds-main-white);
    }
}
