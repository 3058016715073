.marks {
    width: auto;
    height: 100%;
    display: flex;
    z-index: 1;
    position: relative;
    flex-direction: column;
}

.chartWrapper {
    position: relative;

    & > h2 {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        font-family: Inter;
        font-size: 11px;
        font-weight: 400;
        line-height: 12px;
        color: var(--ds-dtsip-text-light-25);
    }

    canvas {
        width: 100% !important;
        height: 100% !important;
    }

    &_legend {
        position: absolute;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: -0.05em;
        text-align: right;
        color: #576273;
    }
}
