.totalMarketHeader {
    gap: 62px;
    margin-right: 37px;
    margin-top: 1px;

    &_info {
        gap: 0px;

        h4 {
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            color: #576273;
        }

        & > div {
            gap: 24px;
        }
    }
}

.info_keyRate {
    span {
        font-family: Inter, serif;
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        letter-spacing: 0.1px;
    }
}

.currency {
    span {
        font-family: Inter, serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }

    &_symbol {
        color: #636e80;
        margin-right: 8px;
    }
}
