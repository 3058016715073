.neutral {
    color: var(--ds-main-black);
}

.positive {
    color: green;
}

.negative {
    rotate: 180deg;

    path {
        fill: #f2433d;
    }
}

.positive,
.negative {
    margin-left: 4px;
}
