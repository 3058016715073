.paymentsTab {
    transform: translateY(-18px);
    gap: 15px !important;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 21.37px;
        text-align: left;
    }
}

.textRowValue {
    font-family: FavoritPro, sans-serif !important;
    font-size: 14px !important;
    font-weight: 300 !important;

    & > span {
        font-family: FavoritPro, sans-serif !important;
        font-size: 14px !important;
        font-weight: 300 !important;
    }
}
