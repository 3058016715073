.label {
    color: #8a96a6;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.input {
    border: 1px solid #d8e1eb;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: 'FavoritPro';
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.5);

    &:hover,
    &:focus {
        border-color: #a5b1c0;
    }

    &[data-disabled='true'] {
        background-color: #f5f7fa;
        color: #8a96a6;
        opacity: 1;
    }

    &[data-error='true'] {
        border: 1px solid #f2433d;
        color: #f2433d;
    }
}

.error {
    line-height: 20px;
    color: #14171f;
    border-radius: 6px;
    border: 1px solid #d8e1eb;
    background: #fff;
    box-shadow: 4px 4px 0px 0px rgba(64, 101, 128, 0.1);
    padding: 8px 12px;
    margin-top: 10px;
    max-width: 80%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 14px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: #d8e1eb;
        padding: 4px;
        transform: rotate(45deg);
        background-color: #fff;
    }
}

.wrapper {
    &[data-with-left-section='true'] {
        .input {
            padding-left: 48px;
        }
    }

    &[data-with-right-section='true'] {
        .input {
            padding-right: 48px;
        }
    }

    &[data-size='lg'] {
        .input {
            height: var(--input-height-sm);
            padding: 10px 12px;
        }
    }
}

.section {
    &[data-position='right'] {
        right: 12px;
        width: max-content;
    }

    &[data-position='left'] {
        left: 12px;
        width: max-content;
    }
}
