.customPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    span {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            background: rgba(216, 225, 235, 0.2);
            border: 1px solid #d8e1eb;
            width: 36px;
            height: 36px;
            padding: 0;
            margin: 0;
            border-radius: 4px;
            min-width: 36px;
            max-width: 36px;
        }
        button {
            min-width: 36px;
            max-width: 36px;
            height: 36px;
            background: rgba(216, 225, 235, 0.2);
            border: 1px solid #d8e1eb;
            border-radius: 4px;
            padding: 0;
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }

    button {
        height: 36px;
        text-align: center;
        background: rgba(216, 225, 235, 0.2);
        border: 1px solid #d8e1eb;
        border-radius: 4px;
        padding: 0;
        margin: 0;

        span {
            color: rgba(138, 150, 166, 1);
            text-align: center;
            padding: 6px 20px;
        }
    }

    .currentPage {
        background: rgba(99, 110, 128, 1);
        color: rgba(255, 255, 255, 1);
        border: 1px solid transparent;
    }
}

.paginatorWrapper {
    width: 100%;
}
