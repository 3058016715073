@import '../../../../shared/commonStyles/main-styles.scss';

.layout {
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: auto !important;
    @include minContentWidth;

    &_contentContainer {
        width: 100%;
    }

    &_content {
        width: 100%;
        max-width: 1920px;
        @include minContentWidth;
        z-index: 1;
    }

    &_backgroundImage {
        @include minContentWidth;
        width: 100%;
        height: 220px;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 116px;
        background-image: url('../../../../modules/Investorpro/shared/images/png/ScreenersPageBg.png');
    }
}
