.tabsWrapper {
    margin: 0;

    & > ul {
        gap: 34px;

        li > a {
            font-family: 'FavoritPro';
            font-style: normal;
            font-weight: 350;
            font-size: 15px;
            line-height: 16px;
            color: var(--ds-dtsip-text-light-25);
        }

        a[aria-selected='true'] {
            font-family: 'FavoritPro';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            color: var(--ds-dtsip-text-black-100);
            text-align: left;
            transition: 0.3s;
        }
    }
}
