.watchlistSidebarWrapper {
    top: 116px !important;
    height: calc(100vh - 116px) !important;
}

.overlayPanelWrapper {
    &:before,
    &:after {
        content: none;
    }
}

.favoritesSidebarWrapper {
    width: 483px;
    position: relative;
    border-top: 1px solid #d8e1eb;
    border-left: 1px solid #d8e1eb;
    box-shadow: none;
    padding: 24px 24px 30px 30px;
}

.headerWrapper {
    font-family: FavoritPro, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--ds-dtsip-text-black-100);
    display: flex;

    & > button {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-left: -6px;
    }
}

.settingsWrapper {
    background: var(--ds-main-white);
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    position: absolute;
    z-index: 3000;
    left: -122px;
    top: 7px;
    width: 256px;

    & > div {
        gap: 11px;
        cursor: pointer;
        padding: 5px 12px;

        &:hover {
            background: #ecf5ff;
        }

        &:first-child {
            padding-top: 10px;
        }

        &:last-child {
            padding-bottom: 10px;
        }

        & > span {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-dtsip-text-black-100);
        }
    }
}

.radioButtonSettings {
    width: 16px;
    height: 16px;

    &_box {
        width: 16px;
        height: 16px;
        border: 1px solid #a5b1c0;
    }

    &_icon {
        width: 10px;
        height: 10px;
        background: var(--ds-bg-dark-blue);
    }

    &[data-p-checked='true'] &_box {
        border-color: var(--ds-bg-dark-blue);
        background: var(--ds-main-white);
    }
}
