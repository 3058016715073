.dropdown {
    border-radius: 6px;
    border: 1px solid #d8e1eb;
    padding: 4px 0 8px 0;
}

.option {
    color: #14171f;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    padding: 4px 18px 4px 12px;
    text-align: left;

    &[data-checked='true'] {
        color: #a5b1c0;
    }

    &:hover {
        background-color: #d7e6f580;
        color: #636e80;
    }
}
