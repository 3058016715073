.wrapper {
    gap: 0px;

    h3 {
        font-family: FavoritPro, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: var(--ds-main-black);
    }

    .table {
        width: 100%;

        table {
            thead th {
                margin-bottom: -1px !important;
                padding-left: 4px !important;
                padding-right: 6px !important;
                padding-top: 0px !important;
            }
            tbody {
                td span {
                    padding-top: 8px !important;
                    padding-bottom: 8px !important;
                    height: 42px !important;
                }
            }
        }
    }
}
