.linearChartWithBarsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .chartWrapper {
        position: relative;

        & > h2 {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            font-family: Inter;
            font-size: 11px;
            font-weight: 400;
            line-height: 12px;
            color: var(--ds-dtsip-text-light-25);
        }
        padding-top: 24px;
    }

    & > div:last-child {
        margin-left: 25px;
    }
}
