.dividendsTabWrapper {
    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 28.49px;
        text-align: left;
    }

    margin-top: -10px;

    .dividendsAmount {
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
    }

    .dividendsAmountMeasure {
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: var(--ds-dtsip-text-light-25);
    }

    gap: 17px;

    .paginator {
        justify-content: flex-start;
        width: 100%;
    }

    .dividentsProfitability {
        padding: 6px 10px 6px 10px;
        border-radius: 4px;
        display: flex;
        gap: 7px;
        background-color: #2f9ceb1a;

        & > span:first-child {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            color: #576273;
            color: var(--ds-main-black);
        }

        .value {
            font-family: Inter, sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: right;
            color: var(--ds-main-black);

            & > span {
                font-family: FavoritPro, sans-serif;
                font-size: 13px;
                font-weight: 350;
                text-align: right;
            }
        }
    }
}

.dividendCell {
    gap: 8px;
    text-align: center;
    vertical-align: center;

    & > span {
        gap: 4px;
        margin-top: 4px;
    }
}

.payoutPeriod {
    font-family: 'FavoritPro', sans-serif;
    font-size: 14px;
    font-weight: 300;
}
