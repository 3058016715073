.ghost {
    cursor: grabbing;
    background-color: #d8e1eb;
}

.drag {
    background-color: #d8e1eb;
}

.chosen {
    cursor: grabbing;
    background-color: #d8e1eb;
}
