.chartWrapper {
    position: relative;

    & > h2 {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        font-family: Inter;
        font-size: 11px;
        font-weight: 400;
        line-height: 12px;
        color: var(--ds-dtsip-text-light-25);
    }

    padding-top: 12px;
}

.tooltip {
    width: 208px;
    height: auto;
    border: 1.5px solid #d8dfe6;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    padding: 9px 18px 13px 18px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    transition: 200ms;

    & > svg {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
    }

    h2 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #14171f;
        margin: 0;
        margin-bottom: 10px;
    }

    h3 {
        font-family: 'FavoritPro', 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 300;
        color: #88919a;
        line-height: 20px;
        margin: 0;
        display: inline-block;
    }

    p {
        font-size: 13px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: #18191b;
        line-height: 20px;
        margin: 0;
        display: inline-block;
    }
}

.container {
    display: flex;
    gap: 4px;
    flex-direction: column;

    .line {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }
}

.red {
    & p {
        color: #f2433d !important;
    }
}

.green {
    & p {
        color: #42a62e !important;
    }
}
