.tooltipWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    visibility: hidden;
    transition: 0.3s;

    &__hidden {
        transition: 0s;
        opacity: 0 !important;
        user-select: none;
    }

    .tooltip {
        width: 208px;
        height: auto;
        border: 1.5px solid #d8dfe6;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 1);
        backdrop-filter: blur(5px);
        padding: 9px 18px 13px 18px;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        transition: 200ms;
        z-index: 100;

        &__transparent {
            background-color: rgba(255, 255, 255, 0.8);
        }

        &__left {
            transform: translate(calc(-100% - 8px), -50%);
        }

        &__right {
            transform: translate(8px, -50%);
        }

        &__top {
            transform: translate(-50%, calc(-100% - 8px));
        }

        &__bottom {
            transform: translate(-50%, 8px);
        }

        &_arrow {
            position: absolute;

            &__left {
                right: -4.2px;
                top: 50%;
                transform: translate(50%, -50%) rotate(-90deg);
            }

            &__right {
                top: 50%;
                transform: translate(-50%, -50%) rotate(90deg);
                left: -4.3px;
            }

            &__top {
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
            }

            &__bottom {
                top: -8.5px;
                rotate: 180deg;
                transform: translateX(50%);
                left: 50%;
            }
        }

        h2 {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #14171f;
            margin: 0;
            margin-bottom: 10px;
        }

        h3 {
            font-family: 'FavoritPro', 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: #88919a;
            line-height: 20px;
            margin: 0;
            display: inline-block;
        }

        p {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            text-align: left;
            margin: 0;
            width: auto;
            display: inline-block;
        }

        .container {
            display: flex;
            gap: 4px;
            flex-direction: column;

            .line {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}
