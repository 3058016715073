.emptyFavoritesWrapper {
    margin: 0 16px 0 30px;
    gap: 20px;
    height: 100%;

    & > button {
        width: 245px;
    }
}

.btn {
    height: 48px;
    border: none;
    border-radius: 6px;
    background-color: var(--ds-bg-dark-blue);
    gap: 4px;

    & > svg {
        width: 14px;
        height: 14px;
        margin: 5px;
    }

    & > span {
        flex: none;
        font-family: FavoritPro, sans-serif;
        font-size: 13px;
        font-weight: 350;
        line-height: 16px;
        text-align: center;
        color: var(--ds-main-white);
    }
}
