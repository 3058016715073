.item {
    min-height: 52px;
    border-bottom: 1px solid #d8e1eb;

    & > :first-child {
        width: 24px;
        height: 24px;
        margin-right: 8px;

        & svg {
            width: 100%;
            height: 100%;
            color: #bfc9d6;
        }
    }
}

.column {
    margin-right: auto;

    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    text-align: left;
}
