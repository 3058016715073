.raitingInfoWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}

.raitingInfoTable {
    tr {
        td:first-child span {
            font-family: FavoritPro, sans-serif !important;
            font-size: 14px !important;
            font-weight: 300 !important;
            color: #576273 !important;
        }
        td:last-child span {
            font-family: Inter, sans-serif !important;
            font-size: 15px !important;
            font-weight: 400 !important;
        }
    }
}
