.headerWrapper {
    width: 100%;
}

.headerPanelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 26px;
    width: 100%;
    max-width: 1920px;
}

.alertsButton {
    position: relative;

    & > span:last-child {
        position: absolute;
        right: 5px;
        top: 4px;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        margin: 0;
        border-radius: 50%;
        background-color: red;
        border: 2px solid var(--ds-main-white);
    }
}

.investorproLogo {
    margin-top: 11px;
    min-width: 264px;
    cursor: pointer;
}

.search {
    svg {
        top: 10px;
        left: 16px;
        color: #a5b1c0;
    }

    button > span {
        color: #a5b1c0;
    }

    input {
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        color: #14171f;
    }
}

.headerLinksBlock {
    flex: 1 1;
    gap: 26px;

    .navigationList {
        display: flex;
        gap: 38px;
        list-style-type: none;

        .navigationItem {
            text-transform: uppercase;
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.26px;
            position: relative;

            .navigationLink {
                color: var(--ds-dtsip-text-black-100);
                transition: transform 0.3s linear;
                min-height: 80px;
                display: flex;
                align-items: center;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 4px;
                z-index: 1;
                background-color: var(--ds-main-blue);
                opacity: 0;
                transition:
                    background-color 0.2s,
                    opacity 0.5s;
            }

            &__active {
                &::after {
                    opacity: 1;
                }
            }

            &.disabled {
                opacity: 0.6;

                a {
                    pointer-events: none;
                }
            }
        }
    }
}

.content {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        position: absolute;
        top: -5px;
        left: 0;

        background: transparent;
    }
}

.overPanelUser,
.overPanelDropDown {
    top: 116px !important;

    border-radius: 6px;
    border: 1px solid #d8e1eb;
    box-shadow: none;

    &::before,
    &::after {
        display: none;
    }
}

.dropdown {
    &_list {
        list-style-type: none;
        width: 173px;
        padding: 16px 18px;
    }

    &_item {
        display: block;
        padding: 8px 0;

        & > a {
            text-decoration: none;

            & > p {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 20px;
                text-align: left;
            }
        }
    }
}

.iconsBtnWrapper {
    gap: 13px;
}

.avatarContainer {
    & > :first-child {
        display: none;
    }

    & > :last-child {
        margin: 0;
    }
}

.iconBtn,
.menuIcon {
    width: 28px;
    height: 28px;
}

// @media (max-width: 1359px) {
//   .headerWrapper {
//     padding: 0 24px;
//   }

//   .headerNavigationList {
//     display: none;
//   }

//   .navigationList {
//     flex-direction: column;
//     align-items: baseline;
//     gap: 10px;
//   }
// }
