.analyticsPage {
    padding: 0 35px 92px 35px;
    gap: 39px;
    margin-top: 1px;

    @media screen and (max-width: 1970px) {
        overflow-x: hidden;
    }

    h1 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        text-align: left;
        color: var(--ds-main-black);
    }
}
