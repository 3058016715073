.measure {
    font-family: FavoritPro, sans-serif;
    font-size: 12px;
    font-weight: 350;
    line-height: 40px;
    text-align: right;
}

.dotSeparator {
    color: #576273;
    font-family: FavoritPro, sans-serif;
    font-size: 8px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
}
