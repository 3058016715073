.table {
    width: 100%;
    display: grid;

    &_headerCell {
        background-color: transparent;
        position: relative;
        font-family: 'FavoritPro', sans-serif;
        font-weight: 350;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-dtsip-text-light-25) !important;
        margin-left: 0;
        margin-top: 3px;
        padding-bottom: 1px;
        padding: 13px;
        box-sizing: border-box;
        padding-left: 18px;
        padding-right: 18px;
        border-right: 2px solid white;

        & > span {
            margin-left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            z-index: 4;
            height: 1px;
            background-color: #636e80;
        }

        &__last {
            border-right: none;
        }
    }

    &_body {
        width: 100%;
    }

    &_empty {
        color: #4b5563;
        padding-top: 2px;
    }

    &_cell {
        position: relative;
        border-right: 2px solid white;

        &__filled > span {
            background-color: rgba(215, 230, 245, 0.2);
        }

        &__left {
            justify-content: flex-start;
        }

        &__right {
            justify-content: flex-end;
        }

        &__center {
            justify-content: center;
        }

        &__last {
            border-right: none;
        }

        & > span {
            height: 42px;
            display: flex;
            align-items: center;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0;
            padding: 13px 18px;
            color: var(--ds-main-black);
            margin-top: 1px;
            margin-bottom: 2px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #d8e1eb;
        }
    }
}
