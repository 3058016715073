.analyticalMapWrapper {
    margin-top: 73px;
    margin-bottom: 82px;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        color: var(--ds-main-black);
        text-align: left;
        letter-spacing: -0.4px;
        margin-bottom: 18px;
    }

    .chartLegend,
    .checkboxBlock {
        gap: 24px;

        & > div {
            gap: 8px;

            .pointMarker {
                width: 8px;
                height: 8px;
                border-radius: 2px;
            }

            .curveMarker {
                width: 20px;
                height: 2px;
            }

            span,
            label {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                text-align: left;
                color: #576273;
            }

            &:last-child span {
                letter-spacing: 0.2px;
            }
        }
    }

    .checkboxBlock {
        margin-top: 18px;

        h4 {
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.05em;
            text-align: left;
            color: #576273;
        }

        label {
            letter-spacing: -0.09px;

            &:last-child {
                letter-spacing: 0.05px;
            }
        }
    }
}

.checkbox {
    border-radius: 2px;
}
