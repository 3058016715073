.root {
    border-radius: 5px;
    border: 1px solid #a5b1c0;
    background: #ffffff;
    padding: 0;
}

.indicator {
    background: #14171f;
}

.label {
    padding: 7px 24px;
    color: #14171f;
    font-weight: 300;
    line-height: 18px;
    font-size: 14px;

    &[data-active='true'] {
        color: #ffffff;
        font-weight: 400;
        line-height: 18px;
        font-size: 14px;
    }
}

.control::before {
    top: 10px !important;
    bottom: 10px !important;
}

.innerLabel {
    color: inherit;
}
