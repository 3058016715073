.tableColumn > span {
    height: 42px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    padding: 13px 18px;
    color: var(--ds-main-black);
}

.alignLeft {
    justify-content: flex-start !important;
}

.alignRight {
    justify-content: flex-end !important;
}

.alignCenter {
    justify-content: center !important;
}

.trimText {
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip {
    width: 140px;
    background-color: white;
    position: absolute;
    transform: translateX(-50%) translateY(-130px);
    border: 1px solid #d8e1eb;
    border-radius: 6px;
    padding: 8px 18px;
    left: 70px;
    top: 0;
    pointer-events: none;
    z-index: 10000;
    user-select: none;
    max-width: 400px;
    transition: opacity 0.2s;
    font-family: FavoritPro, sans-serif;
    font-size: 13px;
    font-weight: 350;
    color: #636e80 !important;

    span {
        position: relative;
    }

    svg {
        position: absolute;
        bottom: -17px;
        right: calc(50% - 14px);
    }
}
