.deleteBtnWrapper {
    width: auto;
    width: 16px;
    height: 18px;
    align-self: center;
    background-color: transparent;
    cursor: pointer;

    path {
        fill: #f2433d;
    }
}
