.chartsBlock {
    margin-top: -10px;
    margin-right: -2px;

    gap: 18px !important;

    & > div:first-child {
        & > div {
            & > div {
                & > div > div {
                    padding-left: 16.5px !important;
                    padding-right: 16.5px !important;
                }
            }
        }
    }
}
