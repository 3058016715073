@import '../../../../../../../shared/commonStyles/main-styles.scss';

.menuWrapper {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    z-index: 100;
    border: 1px solid #d8e1eb;
    border-radius: 6px;
    overflow: hidden;

    &_content {
        height: 100%;
        overflow: auto;
        position: relative;

        @include scrollbar;

        &::after {
            content: '';
            position: absolute;
            top: 54px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #d8e1eb;
            z-index: 1;
        }
    }

    &:not(.isModal) {
        min-width: 640px;
        height: 567px;
        background-color: var(--ds-main-white);
        box-shadow: 4px 4px 0 0 rgba(64, 101, 128, 0.1019607843);
    }

    &.isModal {
        position: static;
        width: 640px;
        margin-top: 30px;
        margin-bottom: 20px;

        & > div {
            box-shadow: none;
            height: auto;
            max-height: 375px;
        }
    }
}

.clearButton {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    right: 6px;
    top: 6px;
    width: 24px;
    height: 24px;

    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.searchInput {
    width: 100%;
    padding: 6px 6px 6px 42px;
}

.tabView {
    margin: 0 !important;

    [data-pc-section='navcontent'] {
        margin: 24px 24px 0;

        & > ul {
            border: none;
            gap: 24px;

            font-size: 15px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.02em;
        }
    }

    [data-pc-section='panelcontainer'] {
        @include scrollbar;
    }

    & > div {
        & > div {
            li a {
                padding-top: 0 !important;
            }
        }

        &:last-child {
            margin-top: 0 !important;
            margin-bottom: 24px;
            border-radius: 0;

            max-height: calc(100% - 54px);
            min-height: calc(100% - 70px);
            overflow-y: auto;
        }
    }
}

.empty {
    &_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #14171f;

        text-align: center;
    }
}
