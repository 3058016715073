.tab {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
    line-height: 16px;
    position: relative;
    color: #8a96a6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    border: 0;
}

.tab[data-active] {
    color: #14171f;
    font-weight: 500;
}

.tab:hover {
    cursor: pointer;
    background-color: transparent;
}

.list {
    gap: 28px;
}

.tab[data-active]::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #f2433d;
}

.list::before {
    border-bottom: 1px solid #d8e1eb;
}

.tabLabel {
    color: inherit;
}
