.loader {
    margin: 0 5px;
    width: 10px;
}

.dots {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: rgba(66, 166, 46, 1);
    color: #42a62e;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before,
    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &::before {
        left: 10px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: #42a62e;
        color: #42a62e;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
    }

    &::after {
        left: -10px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: #42a62e;
        color: #42a62e;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
    }
}

@keyframes dot-flashing {
    0% {
        background-color: #42a62e;
    }
    50%,
    100% {
        background-color: rgba(66, 166, 46, 0.4);
    }
}
