.twoColumnsDataView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0px;
    row-gap: 18px;

    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    & > span,
    a,
    .label {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        letter-spacing: 0.1px;
        padding: 0;
        color: var(--ds-main-black);
    }

    .label {
        color: #576273;
    }

    & > a {
        padding: 0;
        color: var(--ds-bg-dark-blue);
    }

    .value {
        font-family: FavoritPro, sans-serif;
        font-size: 15px;
        font-weight: 350;
        text-align: left;

        & > span {
            font-family: Inter, sans-serif;
            font-size: 15px;
            font-weight: 400;
            text-align: left;

            & > span {
                font-family: FavoritPro, sans-serif !important;
                font-size: 11px !important;
                font-weight: 350 !important;
                text-align: right;
            }
        }

        &_number,
        &_date {
            font-family: Inter, sans-serif;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
        }
    }
}
