.tooltipContent {
    gap: 6px;

    .paymentType {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

        &.offer {
            color: #288c14;
        }

        &.redemption {
            color: #f2433d;
        }

        &.amortization {
            color: #eaa113;
        }

        &.coupon {
            color: #2f9ceb;
        }
    }

    .content {
        gap: 7px;
        display: flex;
        flex-direction: column;

        & > div {
            width: 100%;
            display: flex;
            gap: 12px;
            justify-content: space-between;

            & > span {
                &:first-child {
                    font-family: FavoritPro, sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    text-align: left;
                    color: var(--ds-main-gray);
                }

                &:last-child {
                    font-family: Inter, sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: right;
                    color: var(--ds-main-black);
                }
            }
        }
    }
}
