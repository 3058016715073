.td {
    background: transparent;
    border-right: 2px solid #ffffff;
}

.th {
    background: transparent;
    border-right: 2px solid transparent;
}

.tbody {
    border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--table-border-color);
}

.tbody > tr[data-striped='odd']:nth-child(odd) > td,
.tbody > tr[data-striped='odd']:nth-child(odd) > th {
    background-color: #f7fafd;
}
