.dateNavigation {
    gap: 6px;
    margin-bottom: 10px;

    & > button {
        outline: none;
        width: 30px;
        height: 30px;
        background: #d8e1eb4d;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        outline: none;
        border: none;
        transition: 0.1s;

        svg path {
            stroke: #a5b1c0;
        }

        &:last-child svg {
            transform: rotate(180deg);
        }

        &:disabled {
            cursor: not-allowed;
            background: var(--ds-main-gray);
        }

        &:active {
            background: var(--ds-main-gray);
        }
    }
}
