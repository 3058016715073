.pageWrapper {
    min-height: 100dvh;
    padding: 36px 36px 0 36px;
    background-position-y: var(--app-shell-header-height);
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.navigation {
    padding: 0 36px;
    height: 80px;
}
