.mark {
    height: 20px;
    border-radius: 4px !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0px 8px;
    width: 122px;

    h3 {
        margin: 0;
        font-family: FavoritPro, sans-serif;
        font-size: 13px;
        font-weight: 350;
        line-height: 20px;
        text-align: right;
        color: var(--ds-main-white);
        text-transform: uppercase;
    }

    & > p {
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.05em;
        text-align: right;
        color: var(--ds-main-white);
    }
}

.markChangeValue {
    margin-left: 6px;
}

.priceChart {
    margin-bottom: 24px;
    margin-left: -22px;
}

.tooltipColorLine {
    width: 20px;
    height: 2px;
    margin-right: 9px;
}

.legend {
    gap: 18px;
    margin-bottom: 5px;
    margin-top: 53px;
    flex-wrap: wrap;

    &_item {
        gap: 9px;

        span {
            &:first-child {
                width: 20px;
                height: 2px;
            }

            &:last-child {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 16px;
                text-align: left;
                color: var(--ds-dtsip-text-light-25);
            }
        }
    }
}
