@import '../../../../../../../../shared/commonStyles/main-styles';

$blue-10: #d5eaff;
$blue: #2f9ceb;

$black-10: #d8e1eb;
$gray: #a5b1c0;

.isFavouriteIcon {
    cursor: pointer;
}

.dialog {
    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        top: 178px;
        right: 0;
        left: 0;
        background: var(--ds-main-light-gray);
    }
}

.tabWrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: calc(100% - 2rem);
}

.tabHeader {
    border-color: transparent;
}

.sortWrapper {
    background-color: #f2f7fe;
}

.p-tabview .p-tabview-nav {
    border-color: transparent;
}

.title {
    position: relative;
    font-size: 24px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

    padding: 48px 48px 43px;

    &::after {
        content: '';
        position: absolute;
        bottom: -35px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #d8e1eb;
        z-index: 1;
    }
}

.tabView {
    width: 100%;
    height: 100%;
    max-width: 917px;

    & > div > div > [class~='p-tabview-nav'] {
        border: none;
        gap: 18px;
        padding-top: 5px;

        & > [data-pc-section='inkbar'] {
            background-color: #f2433d;
        }

        & li:last-child {
            height: 3px;
        }
    }

    & a {
        padding-bottom: 14px;
        border: none;
        background: transparent;
    }

    & [class~='p-tabview-selected'] {
        & a span {
            color: var(--ds-main-black);
            font-weight: 500;
        }
    }

    & [class~='p-tabview-panels'] {
        height: calc(100% - 146px);
        max-height: calc(100% - 140px);
        overflow-y: auto;
        margin: 92px 2px 20px 48px;

        @include scrollbar;
    }

    & [data-pc-section='navcontainer'] {
        margin-left: 48px;
    }

    [data-pc-section='nextbutton'],
    [data-pc-section='prevbutton'] {
        border-radius: 0px 4px 4px 0px;
        position: absolute;
        top: 0;
        width: 22px;
        height: 32px;
        box-shadow: none;
        background-color: #dee8f2;
        color: #a5b1c0;
    }

    [data-pc-section='prevbutton'] {
        border-radius: 4px 0px 0px 4px;
    }
}

.search {
    margin: 24px 0;
}

.selectedWrapper {
    width: 475px;
    min-width: 475px;
}

.groupColumns {
    gap: 0 48px;
    margin-bottom: 16px;

    &_column {
        width: 385px;
        max-width: calc(50% - 24px);
        min-height: 52px;
        border-bottom: 1px solid #d8e1eb;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: #14171f;
    }
}

.screenerColumns {
    height: calc(100% - 34px);
    max-height: calc(100% - 34px);
    overflow-y: auto;
    @include scrollbar;

    background-color: #f2f7fe;
    padding: 18px 40px 18px 48px;
    border-left: 1px solid #d8e1eb;

    &_title {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        padding: 5px 40px 13px 48px;
    }
}

.actionsWrapper {
    border-top: 1px solid #d8e1eb;
    padding: 36px 48px;
    gap: 26px;
}

.cancelButton {
    width: 196px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a5b1c0;
    border: none;
    & span {
        color: var(--db-main-white);
        font-weight: 500;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }
}

.createButton {
    width: 196px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ds-bg-dark-blue);
    & span {
        color: var(--db-main-white);
        font-weight: 500;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }
}

.settingsWindow {
    width: 100vw;
    height: 100vh;
    max-width: 1394px;
    max-height: 846px;
}

.contentWrapper {
    height: calc(100% - 230px);
}

.wrapper {
    position: relative;
    width: 100%;
}

.columnsByGroups {
    gap: 0 48px;
}

.searchWrapper {
    position: absolute;

    top: 62px;
    right: 48px;
    left: 48px;
    z-index: 1;

    & > .searchIcon {
        position: absolute;
        color: #a5b1c0;
        top: 12px;
        left: 12px;
    }

    & > .clearIcon {
        position: absolute;
        color: #a5b1c0;
        top: 0;
        right: 0;
        height: 44px;
        width: 44px;
        background: transparent;

        & > span {
            color: #a5b1c0;
        }
    }
}

.searchInput {
    height: 44px;
    width: 100%;
    font-size: 14px;
    font-weight: 350;
    padding: 0 26px 0 40px;
}

.switch {
    flex: 0 0 auto;
    width: 28px;
    height: 16px;

    border: 1px solid;
    border-radius: 8px;
    background-color: var(--ds-main-white);

    & > span {
        width: 24px;
        height: 12px;
        top: 1px;
        left: 1px;

        &::before {
            height: 12px;
            width: 12px;
            margin-top: -6px;
        }
    }

    &[aria-checked='false'] {
        border-color: $black-10;

        & > span {
            background-color: $black-10;

            &::before {
                background-color: $gray;
                left: -1px;
                right: auto;
            }
        }
    }

    &[aria-checked='true'] {
        border-color: $blue-10;

        & > span {
            background-color: $blue-10;
            &::before {
                background-color: $blue;
                left: -9px;
                right: auto;
            }
        }
    }
}
