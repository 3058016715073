.dialog {
    width: 512px;
    height: 196px;
    padding: 53px 48px 46px;
    background-color: var(--ds-main-white);
}

.dialogHeader {
    font-size: 24px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;

    color: var(--ds-dtsip-text-black-100);
}

.dialogContent {
    margin: 24px 0 30px;
    color: #14171f;
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    letter-spacing: 0;
}

.dialogActionButton {
    width: 100%;
    display: block;
    padding: 16px;
    background-color: #a5b1c0;
    border: none;
    font-size: 13px;
    font-weight: 350;
    line-height: 16px;
    text-align: center;
}

.dialogActionButtonSuccess {
    margin-left: 24px;
    margin-right: 0;
    background-color: #2f9ceb;
}
