.contentWrapper {
    gap: 20px;

    & > h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);
    }

    & > input {
        height: 36px;
        padding: 8px 12px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        color: var(--ds-dtsip-text-black-100);

        &::placeholder {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-dtsip-text-light-25);
        }
    }
}

.btnWrapper {
    gap: 26px;

    & > button {
        width: 100%;
        height: 48px;
        padding: 16px 10px;
        border: none;
        border-radius: 6px;

        & > span {
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 350;
            line-height: 16px;
            text-align: center;
            color: var(--ds-main-white);
        }

        &.cancelBtn {
            background: #a5b1c0;
        }

        &.confirmBtn {
            background: var(--ds-bg-dark-blue);
        }
    }
}

.modalContentForm {
    gap: 22px;

    & > div {
        gap: 8px;
    }

    .priceInput {
        width: 100%;
        height: 36px;
        padding: 8px 12px 8px 12px;
        border-radius: 6px;
        border-width: 1px;

        input {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: var(--ds-main-black);
        }
    }

    h3 {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: #576273;
    }

    .searchSecurityDropDown {
        position: absolute;
        width: 548px;
        height: 567px;
    }
}

.editCreateAlertModalWrapper {
    overflow: visible;

    & > div {
        overflow: visible;
    }
}

.editCreateAlertModal {
    width: 400px;
    padding: 48px;
    gap: 36px;
    box-sizing: border-box;
    overflow: visible;
}

.contentWrapper {
    gap: 20px;
    width: 100%;
    position: relative;

    & > h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);
    }

    & .searchInput {
        width: 100%;

        svg path {
            fill: #8a96a6;
        }

        & > input {
            width: 100%;
            padding-left: 37px;
            padding-top: 7px;

            &::placeholder {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 20px;
                text-align: left;
                color: #8a96a6;
            }
        }
    }

    & .priceInput {
        padding: 0;

        input {
            height: 36px;
            border-radius: 6px;
            width: 100%;
            color: var(--ds-dtsip-text-black-100);
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            padding: 0 54px 0 12px;

            &::placeholder {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 20px;
                text-align: left;
                color: var(--ds-dtsip-text-light-25);
            }
        }
    }

    .currency {
        position: absolute;
        right: 13px;
        //styleName: Body/Txt_14;
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        bottom: 7px;
        text-align: right;
        color: var(--ds-dtsip-text-light-25);
    }
}

.btnWrapper {
    gap: 12px;
    width: 100%;

    & > button {
        width: 100%;
        height: 36px;
        padding: 16px 10px;
        border: none;
        border-radius: 6px;

        & > span {
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 350;
            line-height: 16px;
            text-align: center;
            color: var(--ds-main-white);
        }

        &.cancelBtn {
            background: #a5b1c0;
        }

        &.confirmBtn {
            background: var(--ds-bg-dark-blue);
        }
    }

    &.withExtraButton > button {
        width: 110px;
    }
}
