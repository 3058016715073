.scale {
    border-radius: 12px;
    background: linear-gradient(180deg, #f2433d 0.56%, #ffa114 25.71%, #f2d518 50.53%, #9ee117 75.48%, #42a62e 99.44%);
    position: relative;
}

.tooltip {
    background-color: #fff;
    border: 1px solid #d8e1eb;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    padding: 5px 10px;
    position: relative;
}

.selectedDiapason {
    background-color: rgba(244, 234, 30, 0.2);
}

.selectedText {
    font-weight: 500;
    padding-left: 15px;
}

.divider {
    border-color: #fff;
}

.divider:first-child {
    border-color: transparent;
}

.divider:last-child {
    border-color: transparent;
}
