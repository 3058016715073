.tabsContainer {
    gap: 25px;
    width: auto;
    min-height: 800px;
    height: 100%;

    .selectButtonWrapper {
        gap: 18px;
    }
}
