.scenario {
    border: 1px solid #d8e1eb;
    border-radius: 12px;
    max-width: 400px;
    margin: 2px 2px 6px;
}

.scenario:not(:last-child),
.scenarioSelected:not(:last-child) {
    margin-bottom: 6px;
}

.scenario:hover {
    background-color: rgba(47, 156, 235, 0.1);
    border-radius: 8px;
    outline: 2px solid #2f9ceb;
    cursor: pointer;
}

.scenarioSelected {
    background-color: rgba(47, 156, 235, 0.1);
    border-radius: 8px;
    outline: 2px solid #2f9ceb;
    max-width: 400px;
    margin: 2px 2px 6px;
}
