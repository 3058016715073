.wrapper {
    position: absolute;
    right: 34px;
    top: 9px;
}

.actionButton {
    width: 36px;
    height: 36px;
    margin-right: 2px;
    padding: 0;
    background-color: var(--ds-main-white);
    border: 1px solid var(--ds-border-gray);
}

.filterButton {
    height: 36px;
    max-height: 36px;
    margin-left: 11px;
    background-color: #2f9ceb;
    padding: 6px 19px;
    border: none;

    & > span {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 24px;

        color: var(--ds-main-white);
    }

    & > svg {
        margin-right: 8px;
    }
}

.dialog {
    width: 512px;
}

.dialogWrapper {
    margin: 48px;
}

.dialogHeader {
    font-size: 24px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;

    color: var(--ds-dtsip-text-black-100);
}

.dialogContent {
    margin: 24px 0 30px;
    color: #14171f;
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    letter-spacing: 0;
    word-wrap: break-word;
    white-space: normal;
}

.dialogInput {
    width: 100%;
    padding: 14px 24px;
    margin: 38px 0 26px;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}

.dialogActionButton {
    width: 100%;
    display: block;
    padding: 13px;
    background-color: #a5b1c0;
    border: none;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
}

.dialogActionButtonSuccess {
    margin-left: 24px;
    margin-right: 0;
    background-color: #2f9ceb;
}
