$minHeight: 36px;
$verticalPadding: 9px;
$activeHorizontalPadding: 36px;

@keyframes showText {
    from {
        width: 0;
        height: 0;
    }

    to {
        width: 100%;
        height: auto;
        opacity: 1;
    }
}

.helpline {
    position: fixed;
    bottom: 0;
    cursor: pointer;
    right: 36px;
    display: flex;
    height: auto;
    min-height: $minHeight;
    background: #a5b1c0;
    width: 91px;
    max-width: 1000000000px;
    transition-property: border-radius, left, width, right, pa;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: $verticalPadding 16px;
    z-index: 1000;

    &:hover {
        background: #636e80;
    }

    &__active {
        border-radius: 0;
        width: 100%;
        right: 0;
        padding-left: $activeHorizontalPadding;
        padding-right: $activeHorizontalPadding;

        .openIcon {
            transform: rotate(180deg);
        }

        .content p {
            animation: showText 0.001s ease-in-out forwards;
            animation-delay: 0.2s;
        }
    }

    &[data-document-has-overlay='true'] {
        &[data-document-has-horizontal-scroll='true'] {
            min-height: calc($minHeight + var(--scrollbar-width));
            padding-bottom: calc($verticalPadding + var(--scrollbar-width));
        }

        &[data-document-has-vertical-scroll='true']:not(.helpline__active) {
            transform: translateX(calc(0px - var(--scrollbar-width)));
        }

        &[data-document-has-vertical-scroll='true'].helpline__active {
            padding-right: calc($activeHorizontalPadding + var(--scrollbar-width));
        }
    }
}

.infoIcon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid var(--ds-main-white);
    color: var(--ds-main-white);
    font-size: 12px;
    font-weight: 500;
    flex-shrink: 0;
}

.openIcon {
    height: auto;
    flex-shrink: 0;

    path {
        stroke: var(--ds-main-white);
    }
}

.contentWrapper {
    max-width: 1920px;
    width: 100%;
}

.content {
    gap: 12px;
    width: 100%;

    p {
        color: var(--ds-main-white);
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        text-align: left;
        height: 0;
        width: 0;
        opacity: 0;
    }
}
