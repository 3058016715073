.description {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 575px;

    p {
        font-family: FavoritPro, sans-serif;
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;
    }

    &_content {
        margin-top: 18px;
        overflow: hidden;
        max-height: 96px;
        transition: max-height 0.3s;
        text-overflow: ellipsis;

        &__opened {
            max-height: 500px;
        }
    }

    & > button {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        border: none;
        outline: none;
        color: var(--ds-bg-blue);
        background-color: transparent;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-style: dashed;

        &:hover {
            text-decoration: none;
        }
    }
}
