.buttonsWrapper {
  display: flex;
}

.Button {
  width: 60px;
  height: 60px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ds-main-red);
  cursor: pointer;

  &:disabled {
    background: var(--ds-main-white);
  }
}

.RightButton {
  position: relative;
  transform: rotate(180deg);
}

.IconWrapper {
  position: relative;
  overflow: hidden;
  width: 22px;
  height: 22px;

  & div {
    position: absolute;
    right: 0;
    width: 22px;
    height: 22px;

    &:hover {
      transform: translateX(-100%);
      transition: 300ms ease-in;
    }
  }

  & svg {
    width: 22px;
    height: 22px;
  }

  &.Red div::before,
  &.White div::before {
    content: "";
    background-size: cover;
    position: absolute;
    right: -100%;
    width: 22px;
    height: 22px;
  }

  &.Red div::before {
    background-image: url("../../../../shared/images/svg/ArrowRed.svg");
  }

  &.White div::before {
    background-image: url("../../../../shared/images/svg/ArrowWhite.svg");
  }
}

@media (max-width: 767px) {
  .Button {
    width: 40px;
    height: 40px;
  }

  .IconWrapper {
    width: 14px;
    height: 14px;

    & svg,
    & div,
    &.Red div::before,
    &.White div::before {
      width: 14px;
      height: 14px;
    }
  }
}
