.pageTitle {
    padding: 24px 36px 26px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;

    & > nav {
        background: none;
        border: none;
        font-size: 13px;
        font-weight: 350;
        line-height: 20px;

        margin-bottom: 30px;

        & li {
            &:first-child {
                display: none;
            }
            span {
                color: var(--ds-dtsip-text-light-25);
            }

            &:last-child span {
                color: #576273;
            }
        }
    }
}

.breadcrumbSeparator {
    margin: 0 10px;
    user-select: none;
}

.screenerLogo {
    margin-right: 20px;
}

.wrapper {
    position: relative;
}

.addScreenerButton {
    position: absolute;
    left: 36px;
    top: 10px;
    width: 36px;
    height: 36px;

    padding: 10px;
    z-index: 1;
    background-color: var(--ds-main-white);
    color: var(--ds-main-blue);

    border: 1px solid var(--ds-border-gray);
}

.tabView {
    margin: 0 36px 0;

    &_navContainer {
        border-bottom: 1px solid #d8e1eb;
        max-width: calc(100% - 310px);
        margin-left: 50px;

        [data-pc-section='nextbutton'],
        [data-pc-section='prevbutton'] {
            border-radius: 0 4px 4px 0;
            position: absolute;
            top: 10px;
            width: 22px;
            height: 32px;
            box-shadow: none;
            background-color: #dee8f2;
            color: #a5b1c0;
        }

        [data-pc-section='prevbutton'] {
            border-radius: 4px 0 0 4px;
        }
    }

    &_nav {
        background: transparent;
        border: none;

        li {
            &:not(:first-child),
            &:not(:last-child) {
                margin: 0 14px;
            }

            &:first-child {
                margin-right: 14px;
                margin-left: 0;
            }

            &:last-child {
                background-color: #f2433d;
                height: 3px;
                margin: 0;
            }

            & > a {
                padding: 14px 0;
                border: none;
                background: transparent;
                font-size: 15px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0;
                text-align: left;

                & > span {
                    font-size: 15px;
                    font-weight: 350;
                    line-height: 16px;
                    color: var(--ds-dtsip-text-light-25);
                }
            }
        }

        [aria-selected='true'] > span {
            font-weight: 500;
            color: #14171f;
        }
    }

    &_panelContainer {
        background: transparent;
        border: none;
        margin-top: 5px;

        thead {
            background: transparent;

            & > tr {
                background: transparent !important;

                & > th {
                    border-right: 2px solid transparent;

                    &:first-child {
                        padding: 0 9px;
                    }

                    &[data-p-frozen-column='true'] {
                        background: #f8fcfe;
                    }
                }
            }
        }

        tbody > tr {
            height: 45px;

            &:hover,
            &:hover > [data-p-frozen-column='true'] {
                background: #e0f0fc;
            }
        }
    }
}
