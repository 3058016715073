.nameColumn * {
    font-family: FavoritPro, sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--ds-main-black);
}

.valueColumn * {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: var(--ds-main-black);
}

.tableDataView {
    gap: 8px;
    padding-left: 1px;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-right: 10px;
    }
}
