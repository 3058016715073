.tableHeader {
    border-color: transparent;
}

.tableWithCheckbox {
    overflow: hidden;

    & > div {
        overflow: hidden !important;
    }
}

.tableColumn {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    padding: 0;

    & * {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
    }

    position: relative;
    background-color: transparent;
    border-bottom: none;
    height: 33px;
    text-wrap: nowrap;
    margin: 0;

    color: var(--ds-main-black);
    margin-right: 2px;

    & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        height: auto;
        width: 100%;
        padding: 0 18px 0 0px;
        display: flex;
        height: 100%;
        align-items: center;

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }

        &.center {
            justify-content: center;
        }

        border-right: 2px solid var(--ds-main-white);
    }
}

.checkbox {
    border-radius: 2px;
    width: 16px;
    height: 16px;
}

.tableHeader {
    th {
        padding-right: 18px !important;
        background-color: transparent;
        margin-right: 2px !important;
        margin-bottom: 0;
        border-bottom: none;

        & > div > span {
            font-size: 14px;
            font-weight: 350;
            line-height: 16px;
            text-align: left;
            text-align: left;
            color: var(--ds-dtsip-text-light-25) !important;
        }

        &:first-child {
            width: 32px;
        }

        border-right: 2px solid var(--ds-main-white);
    }

    &:not(.withoutHeader) {
        th {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                z-index: 4;
                height: 1px;
                background-color: #636e80;
            }

            & > div {
                margin-bottom: 1px;
            }
        }
    }
}

.content {
    tr {
        position: relative;

        td:first-child {
            width: 32px;
            padding: 0 8px;
            height: 32px;
            border-bottom: none;
        }

        td:nth-child(2) span {
            font-weight: 300;
            font-family: 'FavoritPro', sans-serif;
            font-size: 14px;
            letter-spacing: 0.1px;
        }
    }

    &.withNavigate tr td:nth-child(2):hover * {
        cursor: pointer;
        color: var(--ds-bg-dark-blue);
    }

    tr:nth-child(2n + 1) > td {
        background-color: rgba(215, 230, 245, 0.2);
    }
}

.tooltipCustom {
    &_text {
        color: var(--ds-main-white);
        background-color: #282f39;
    }

    &_arrow {
        border-top-color: #282f39;
    }
}
