.label {
    font-size: 14px;
    font-weight: 350;
    text-align: left;
    letter-spacing: -0.1px;
    padding-left: 13px;
}

.panel {
    border: 1px solid #d8e1eb;
    background: var(--ds-main-white);
    padding-top: 2px;
    gap: 2px;
    padding: 4px 0px 8px 0px;
    border-radius: 6px;
    transform: translateY(2px);

    li {
        color: var(--ds-main-black);
        font-family: FavoritPro, sans-serif;
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 350;
        text-align: left;
    }
}
