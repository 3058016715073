.wrapper {
    position: relative;
    max-width: 640px;
    width: 100%;
}

.searchWrapper {
    position: relative;
    width: 100%;

    & > svg {
        top: 9px;
    }

    &.isModal {
        min-width: 630px;

        & > svg {
            top: 14px;
            left: 16px;
        }

        & > input {
            height: 44px;

            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: #14171f;

            &::placeholder {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 20px;
                text-align: left;
                color: var(--ds-dtsip-text-light-25);
            }
        }

        & > button {
            top: 10px;
        }
    }
}

.clearButton {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    right: 6px;
    top: 6px;
    width: 24px;
    height: 24px;

    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.searchInput {
    width: 100%;
    padding: 6px 42px 6px 42px;
    height: 36px;
}
