@import '../../../../../../../../shared/commonStyles/main-styles.scss';

.tabWrapper {
    gap: 17px;
    height: 100%;

    .searchBlock {
        .addAlertButton {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            border-radius: 6px;
            background-color: var(--ds-bg-dark-blue);
        }

        gap: 8px;
    }
}

.actionWrapper {
    display: none;
    background: transparent;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
}

.alertsTable {
    overflow: visible;
    width: calc(100% + 14px);
    height: calc(100% - 53px);

    [data-pc-section='wrapper'] {
        @include scrollbar;
    }

    & > div {
        max-height: 100%;
    }

    thead {
        background-color: var(--ds-main-white);
        z-index: 10000000;

        th:last-child::before {
            height: 0 !important;
        }
    }

    tbody {
        & tr:hover > td:last-child button {
            display: flex;
        }

        tr td:nth-child(1) {
            width: 180px;
        }

        & td:last-child {
            overflow: visible;

            & > span {
                padding: 0;
                overflow: visible;
                background-color: var(--ds-main-white) !important;
            }

            &::after {
                height: 0 !important;
            }
        }

        tr:hover {
            td > span {
                background-color: rgba(47, 156, 235, 0.2) !important;
            }

            td:last-child > span {
                background-color: transparent !important;
            }
        }
    }
}

.overlayPanelWrapper {
    border: 1px solid var(--ds-border-gray);
    box-shadow: none;
    border-radius: 6px;
    width: 175px;
    padding: 10px 0;

    &_menuItem {
        padding: 7px 19px;
        cursor: pointer;
        width: 100%;
        border-radius: 0;

        & > span {
            margin-left: 12px;
            font-size: 15px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: #576273;
        }
    }

    &::before,
    &::after {
        content: none;
    }
}

.contentWrapper {
    gap: 10px;
    width: 136px;

    & > span {
        font-family: FavoritPro, sans-serif;
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: #576273;
    }
}

.readAlertIcon {
    path {
        stroke: #2f9ceb;
    }
}
