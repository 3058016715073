.loadingWrapper {
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
}

.loader {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 60px;
    height: 30px;
    z-index: 99999;

    aspect-ratio: 1;
    --c: linear-gradient(#636e80 0 0);
    --r1: radial-gradient(farthest-side at bottom, #636e80 93%, #0000);
    --r2: radial-gradient(farthest-side at top, #636e80 93%, #0000);
    background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2), var(--c),
        var(--r1), var(--r2);
    background-repeat: no-repeat;
    animation: l2 1.5s infinite alternate;
}

@keyframes l2 {
    0%,
    20% {
        background-size:
            8px 0,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px;
        border-radius: 4px, 4px, 4px, 4px;
        background-position:
            0 50%,
            0 calc(50% - 2px),
            0 calc(50% + 2px),
            25% 50%,
            25% calc(50% - 2px),
            25% calc(50% + 2px),
            50% 50%,
            50% calc(50% - 2px),
            50% calc(50% + 2px),
            75% 50%,
            75% calc(50% - 2px),
            75% calc(50% + 2px);
    }

    40% {
        background-size:
            8px 100%,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px;
        background-position:
            0 50%,
            0 calc(0% - 2px),
            0 calc(100% + 2px),
            25% 50%,
            25% calc(50% - 2px),
            25% calc(50% + 2px),
            50% 50%,
            50% calc(50% - 2px),
            50% calc(50% + 2px),
            75% 50%,
            75% calc(50% - 2px),
            75% calc(50% + 2px);
    }

    60% {
        background-size:
            8px 100%,
            8px 4px,
            8px 4px,
            8px 100%,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px;
        background-position:
            0 50%,
            0 calc(50% - 2px),
            0 calc(50% + 2px),
            25% 50%,
            25% calc(50% - 2px),
            25% calc(50% + 2px),
            50% 50%,
            50% calc(50% - 2px),
            50% calc(50% + 2px),
            75% 50%,
            75% calc(50% - 2px),
            75% calc(50% + 2px);
    }

    80% {
        background-size:
            8px 100%,
            8px 4px,
            8px 4px,
            8px 100%,
            8px 4px,
            8px 4px,
            8px 100%,
            8px 4px,
            8px 4px,
            8px 0,
            8px 4px,
            8px 4px;
        background-position:
            0 50%,
            0 calc(50% - 2px),
            0 calc(50% + 2px),
            25% 50%,
            25% calc(50% - 2px),
            25% calc(50% + 2px),
            50% 50%,
            50% calc(50% - 2px),
            50% calc(50% + 2px),
            75% 50%,
            75% calc(50% - 2px),
            75% calc(50% + 2px);
    }

    100% {
        background-size:
            8px 100%,
            8px 4px,
            8px 4px,
            8px 100%,
            8px 4px,
            8px 4px,
            8px 100%,
            8px 4px,
            8px 4px,
            8px 100%,
            8px 4px,
            8px 4px;
        background-position:
            0 50%,
            0 calc(50% - 2px),
            0 calc(50% + 2px),
            25% 50%,
            25% calc(50% - 2px),
            25% calc(50% + 2px),
            50% 50%,
            50% calc(50% - 2px),
            50% calc(50% + 2px),
            75% 50%,
            75% calc(50% - 2px),
            75% calc(50% + 2px);
    }
}
