.addFavouriteButton {
    width: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    padding: 8px 20px 8px 16px;
    color: var(--ds-main-black);
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    background-color: var(--ds-main-white);
    cursor: pointer;

    &[disabled] {
        cursor: default;
        opacity: 0.6;
    }

    &:hover:not([disabled]) {
        background-color: rgba(47, 156, 235, 0.1);
        border-color: rgba(47, 156, 235, 1);
    }
}
