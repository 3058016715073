.baseInformationTabWrapper {
    gap: 93px;
    display: flex;
    justify-content: space-between;

    .generalInfo {
        display: flex;
        flex-direction: column;
        gap: 36px;
        width: 100%;
        max-width: 542px;
    }

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        color: var(--ds-main-black);
    }

    .borrower {
        color: var(--ds-bg-blue);
        font-family: FavoritPro, sans-serif !important;
        font-size: 15px !important;
        font-weight: 350 !important;
    }

    .aboutInfo {
        column-gap: 6px;
    }
}
