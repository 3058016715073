.priceRange {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--ds-main-black);
    margin-top: 0px;

    &_track {
        position: relative;
        border-radius: 10px;
        height: 4px;
        background: rgba(165, 177, 192, 0.7);
        width: 160px;
    }

    &_point {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        box-sizing: content-box;
        border: 1.5px solid var(--ds-main-white);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #636e80;
    }

    &__growth {
        .priceRange {
            &_point {
                background: #288c14;
            }

            &_track {
                background: rgba(66, 166, 46, 0.6);
            }
        }
    }

    &__fall {
        .priceRange {
            &_point {
                background: #f2433d;
            }

            &_track {
                background: rgba(242, 67, 61, 0.6);
            }
        }
    }
}
