.datePeriodSelector {
    display: flex;
    gap: 4px;

    .calendar {
        width: auto;
        svg {
            color: #a5b1c0;
        }

        height: 36px;

        &.active svg {
            color: var(--ds-bg-dark-blue);
        }
    }
    .calendarInput {
        border-right-width: 0;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

        & + button {
            border-left-width: 0;
        }
    }

    .datePeriodItem {
        width: 37px;
        height: 36px;
        border: 1px solid #d8e1eb;
        border-radius: 4px;
        background-color: var(--ds-bg-white);
        cursor: pointer;
        gap: 2px;

        & > div {
            gap: 1px;
        }

        .value {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #576273;
            letter-spacing: 0.5px;
        }

        .measure {
            font-family: 'FavoritPro', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #576273;
        }

        &.active {
            background: rgba(47, 156, 235, 0.1);
            border-color: rgba(47, 156, 235, 0.2);

            .value,
            .measure {
                color: var(--ds-main-black);
            }
        }

        &:disabled {
            cursor: wait;
        }
    }
}
