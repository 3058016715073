.notificationWrapper {
  border-radius: 12px;
  min-width: 29px;
  height: 24px;

  &.red {
    background-color: var(--ds-main-red);
  }

  &.blue {
    background-color: var(--ds-main-blue);
  }

  & > span {
    display: block;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--ds-main-white);
  }
}
