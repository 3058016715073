.measure {
    font-family: FavoritPro, sans-serif;
    font-size: 12px;
    font-weight: 350;
    text-align: right;
}

.isin,
.duration {
    & div {
        font-family: Inter !important;
        font-size: 15px !important;
        font-weight: 400 !important;
    }
}
