.breadCrumb {
    margin-bottom: 20px;
    padding-left: 36px;
    padding-top: 22px;
    border: none;
    outline: none;
    position: relative;
    background-color: transparent;

    li {
        margin-right: 10px;

        & > a > span,
        & > span,
        & > a {
            text-decoration: none;
            font-family: FavoritPro, sans-serif;
            font-weight: 350;
            font-size: 13px;
            letter-spacing: -0.4px;
            color: var(--ds-dtsip-text-light-25);

            &:visited {
                color: var(--ds-dtsip-text-light-25);
            }
        }
    }

    .current {
        color: #576273;
    }
}
