.quotes {
    width: 417px;

    table {
        width: 417px;
    }

    th > div > span {
        width: 100%;
    }

    tbody {
        tr {
            td:first-child {
                width: 220px !important;

                & > span {
                    width: 220px !important;
                }
            }

            &:first-child > td {
                &:first-child > span > div:last-child > span:first-child {
                    color: #288c14 !important;
                }

                &:last-child > span > div:last-child > span:last-child {
                    color: #e42821 !important;
                }
            }
        }
    }

    td {
        width: 207px;

        & > span {
            position: relative;

            & > div:last-child {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;
                padding: 13px 18px;

                & > span {
                    font-family: 'Inter', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0;
                    color: var(--ds-main-black);
                }
            }
        }

        border-right: 3px solid white !important;

        &:last-child {
            border-right: none !important;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & > span {
            font-family: 'FavoritPro', sans-serif;
            font-weight: 350;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0;
            text-align: left;
            color: var(--ds-dtsip-text-light-25) !important;
        }
    }

    .bid,
    .ask {
        position: absolute;
        top: 0;
        height: 0;
        height: 100%;
    }

    .ask {
        left: 0;
        background-color: #ffd1d1;
    }

    .bid {
        right: 0;
        background-color: #ddede1;
    }
}

.quote {
    width: 207px !important;

    span {
        height: auto;
        width: auto;
    }
}
