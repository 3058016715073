.headerWrapper {
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 50px;
    position: relative;
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        'logo title'
        'gap columns';
    row-gap: 18px;
    column-gap: 19px;
    position: relative;

    .addToFavorites {
        position: absolute;
        right: 0;
        top: 12px;
    }

    img,
    .imgStub {
        grid-area: logo;
        width: 48px;
        height: 48px;
        background: var(--ds-bg-blue-grey);
        color: var(--ds-main-white);
        border-radius: 50%;
        font-size: 22px;
        font-family: FavoritPro, sans-serif;
    }

    .imgStub {
        padding-top: 2px;
    }

    .titleBlock {
        grid-area: title;
        gap: 0;

        h1 {
            font-family: FavoritPro, sans-serif;
            font-size: 24px;
            font-weight: 400;
            text-align: left;
            color: var(--ds-main-black);
            margin-top: 4px;
        }

        & > div {
            margin-top: -4px;
            gap: 8px;

            & * {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 16px;
                text-align: left;
                color: #576273;
            }
        }
    }

    .columns {
        grid-area: columns;
        display: flex;
        gap: 69px;

        .currentValueBlock {
            gap: 22px;

            .currentValue {
                font-family: Inter, sans-serif;
                font-size: 44px;
                font-weight: 500;
                text-align: right;
                color: var(--ds-main-black);
                height: 44px;
                line-height: 44px;
                white-space: nowrap;

                & > span {
                    font-family: FavoritPro, sans-serif;
                    font-size: 15px;
                    font-weight: 350;
                    text-align: left;
                    color: #576273;
                }
            }

            .changesBlock {
                gap: 1px;

                & > span:first-child {
                    font-family: FavoritPro, sans-serif;
                    font-size: 13px;
                    font-weight: 300;
                    text-align: left;
                    color: #576273;
                }

                .changedRelativeBlock {
                    gap: 12px;

                    .changedRelative {
                        display: inline !important;
                        padding: 0;
                        margin: 0;
                        font-size: 15px;
                        font-weight: 400;
                        font-family: Inter, sans-serif;
                    }
                }
            }
        }

        .column {
            gap: 1px;
        }

        .columnTitle {
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 300;
            text-align: left;
            color: #576273;
            white-space: nowrap;
        }

        .columnValue {
            font-family: FavoritPro, sans-serif;
            font-size: 15px;
            font-weight: 350;
            text-align: left;
            padding: 0;
            display: flex;
            align-items: flex-start;
            white-space: nowrap;

            & > span {
                font-family: Inter, sans-serif !important;
                font-size: 15px !important;
                font-weight: 400 !important;
                text-align: left;

                & > span {
                    font-family: FavoritPro, sans-serif !important;
                    font-size: 12px !important;
                    font-weight: 350 !important;
                    text-align: right;
                }
            }
        }
    }

    .subscribeButton {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.measure {
    font-family: FavoritPro, sans-serif;
    font-size: 12px;
    font-weight: 350;
    line-height: 18px;
    text-align: right;
}
