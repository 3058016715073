.stockEvents {
    display: grid;
    grid-template-columns: auto 1fr 50px auto 1fr;
    margin-top: 10px;

    &_item {
        padding: 17px 0 17px 11px;
        border-bottom: 2px solid var(--ds-main-light-gray);
        gap: 28px;

        &:first-child,
        &:nth-child(2) {
            padding-top: 0;
        }

        &:last-child,
        &:nth-last-child(2) {
            border-bottom: none;
        }
    }
}

.item {
    &_date {
        gap: 6px;
        padding-right: 32px;
        padding-bottom: 15px;
        padding-left: 11px;
    }

    &_info {
        gap: 2px;

        h4 {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-dtsip-text-black-100);
        }

        & > div {
            gap: 8px;
        }

        span {
            font-family: FavoritPro, sans-serif;
            font-size: 12px;
            font-weight: 350;
            text-align: left;
            color: var(--ds-dtsip-text-light-25);
        }
    }

    &_info,
    &_date {
        margin-bottom: 16px;
        border-bottom: 2px solid var(--ds-main-light-gray);
    }
}

.date {
    &_day {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: center;
        color: var(--ds-dtsip-text-black-100);
    }

    &_month {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 10.4px;
        text-align: center;
        color: var(--ds-dtsip-text-light-25);
    }
}

.eventsWrapper {
    gap: 11px;
    width: 735px;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-right: 10px;
    }
}
