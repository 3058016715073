@import '../../../../../../../shared/commonStyles/main-styles.scss';

.totalMarketDashboardWrapper {
    margin-left: 36px;
    height: 100%;

    h1 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0.1px;
        text-align: left;
        color: var(--ds-main-black);
    }
}

.breadCrumb {
    padding-left: 0;
    margin-bottom: 16px;
}

.indexes {
    &_wrapper {
        background-color: var(--ds-main-white);
        padding: 0 30px 0 19.175px;
        border: 1px solid #d7e6f5;
        width: 632px;
        position: relative;
        flex-shrink: 0;
        max-height: 100%;
        overflow: hidden;
        height: 906px;
        padding-bottom: 8px;
        border-bottom: transparent;
    }

    &_content {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;

        & > div {
            margin-bottom: 15px;

            &:first-child,
            &:last-child {
                margin-bottom: 0;
            }
        }

        @include scrollbar;

        h2 {
            margin-bottom: 4px !important;
        }

        &__shortHeaderSymbols h2 {
            letter-spacing: -0.5px;
        }
    }
}

.content {
    &_sectorIndexes {
        h2 {
            margin-bottom: 4px !important;
            letter-spacing: -0.2px;
        }
    }

    &_header {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        letter-spacing: -0.1px;
        margin-bottom: 4px;

        & + div {
            position: sticky;
            top: -3px;
            z-index: 100;
            background-color: var(--ds-main-white);
            margin-bottom: 0;

            tbody {
                display: none;
            }

            table {
                th {
                    padding-bottom: 4px !important;
                }
            }
        }
    }

    &_currencies {
        margin-bottom: 1px;
    }
}

.lastUpdate {
    font-family: FavoritPro, sans-serif;
    font-size: 13px;
    margin-top: 4px;
    margin-right: 2px;
    margin-bottom: 2px;
    font-weight: 300;
    line-height: 24px;
    text-align: right;
    color: #576273;
}
