.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 14px 16px 14px 24px;
    background-color: var(--ds-main-white);
    height: 64px;
    border-bottom: 1px solid #d8e1eb;
    cursor: pointer;

    &:hover {
        background-color: rgba(239, 245, 251, 1);
    }
}

.title {
    font-size: 14px;
    font-weight: 350;
    color: #14171f;

    line-height: 16px;
    margin-bottom: 6px;

    max-width: 335px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.secText {
    font-size: 12px;
    font-weight: 350;
    line-height: 12px;
    color: var(--ds-dtsip-text-light-25);
}

.tools {
    display: flex;
    align-items: center;
    gap: 27px;
    position: relative;
}

.flagBox {
    display: flex;
    align-items: center;
    gap: 6px;
    &_text {
        font-size: 14px;
        font-weight: 350;
        line-height: 14px;
        color: #14171f;
    }

    &_icon {
        width: 28px;
        height: 18px;
    }
}

.securityType {
    width: 70px;
    padding-left: 7px;

    font-family: FavoritPro, sans-serif;
    font-size: 12px;
    font-weight: 350;
    line-height: 12px;
    color: var(--ds-dtsip-text-light-25);
}

.favoriteBox {
    min-width: 36px;
    height: 36px;
    gap: 10px;
    padding: 7px 13px;
    border: 1px solid #a5b1c0;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    -webkit-border-radius: 6px;
    background-color: var(--ds-main-white);

    &__disabled {
        opacity: 0.6;
        background-color: transparent;
        cursor: default;

        span {
            cursor: default;
        }
    }
}

.angleDownIcon {
    color: #a5b1c0;
    cursor: pointer;
}
.angleDownOpen {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.dropdown {
    position: absolute;
    right: 0;
    top: 44px;
    z-index: 1000;

    width: 260px;
    max-height: 124px;

    padding: 4px 0 8px 0;

    overflow: auto;

    background-color: var(--ds-main-white);

    border: 1px solid #d8e1eb;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 4px 18px 4px 12px;
        background-color: var(--ds-main-white);

        &:hover {
            background-color: #d7e6f580;
            & > .dropdown_itemText {
                color: #636e80;
            }
            & > .dropdown_itemIcon {
                color: #2f9ceb;
            }
        }
    }

    &_itemText {
        font-size: 14px;
        color: var(--ds-dtsip-text-black-100);
        line-height: 20px;
    }
}
