.root {
    background-color: var(--notification-color);
    border-radius: 6px;
    backdrop-filter: blur(4px);
    align-items: flex-start;
    box-shadow: none;
    padding: 24px 40px 24px 24px;
}

.title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 5px;
}

.icon {
    width: 40px;
    height: 40px;
}

.closeButton {
    margin-top: -15px;
    margin-right: -30px;
    --cb-icon-size: 20px !important;
}
