.candles {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: -7px;
    position: relative;
    height: fit-content;
}

.periodSelector {
    margin-left: 9px;
}
