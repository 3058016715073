.tooltip {
    width: 365px !important;
}

.paymentsChart {
    width: auto !important;
    margin-top: -11px;
}

.paymentsChartBlock {
    width: 1213px;
}
