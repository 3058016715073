.mark {
    top: 0;
    right: 0;
    z-index: 100;

    &_deleteButton {
        translate: 100% -50%;
        cursor: pointer;
        position: absolute;
        right: 7px;
        top: 50%;
        padding: 10px;

        svg path {
            fill: #a5b1c0;
        }
    }

    &_markContent {
        display: inline-block;
        width: 100%;
        gap: 8px;
        display: inline-block;
        width: 100%;
        user-select: none;
        position: relative;

        & > svg {
            position: absolute;
        }

        &[data-pos='left'] {
            & > svg {
                left: -5px;
                top: 50%;
                transform: translateY(-50%);
            }

            &[data-with-arrow='true'] {
                transform: translateX(5px);
            }
        }

        &[data-pos='right'] {
            & > svg {
                right: -5px;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
            }

            &[data-with-arrow='true'] {
                transform: translateX(-5px);
            }
        }

        &[data-pos='top'] {
            & > svg {
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
                top: -12.57px;
            }

            transform: translateX(-50%);

            &[data-with-arrow='true'] {
                transform: translateX(-50%) translateY(5px);
            }
        }

        &[data-pos='bottom'] {
            & > svg {
                left: 50%;
                transform: translateX(-50%) rotate(-90deg);
                bottom: -12.57px;
            }

            transform: translateX(-50%);

            &[data-with-arrow='true'] {
                transform: translateX(-50%) translateY(-5px);
            }
        }
    }
}
