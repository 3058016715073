.tabSelector {
    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-top: 2px;
    }

    gap: 13px !important;
    width: 417px;

    table td > span {
        height: 42px !important;
    }

    & > div:first-child {
        & > div {
            & > div {
                & > div > div {
                    padding-left: 16.5px !important;
                    padding-right: 16.5px !important;
                }
            }
        }
    }

    table {
        th {
            padding-left: 4px !important;
        }
    }
}
