.captionTooltip {
    position: absolute;
    z-index: 1000;
    transition: 0.1s;

    &__hidden {
        opacity: 0;
        visibility: hidden;
        user-select: none;
    }

    &_mark {
        padding: 9px 18px;
        border-radius: 6px;
        text-wrap: nowrap;
        flex-wrap: nowrap;
        width: auto;
        height: 41.5px;
        color: var(--ds-main-white);
        background: #636e80;
    }

    &_arrow {
        z-index: 1000000;
        position: absolute;
    }

    &__right {
        .captionTooltip_arrow {
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg) translateY(calc(200% - 0.2px));
        }

        transform: translate(15px, -50%);
    }

    &__left {
        .captionTooltip_arrow {
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg) translateY(calc(200% - 0.2px));
        }

        transform: translate(calc(-100% - 15px), -50%);
    }

    &__bottom {
        .captionTooltip_arrow {
            rotate: 180deg;
            top: 0;
            left: 50%;
            transform: translate(50%, calc(100% - 1px));
        }

        transform: translate(-50%, 12px);
    }

    &__top {
        .captionTooltip_arrow {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(7.8px);
        }

        transform: translate(-50%, calc(-100% - 15px));
    }
}
