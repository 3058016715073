.scale {
    border-radius: 12px;
    background: linear-gradient(-90deg, #f2433d 0.56%, #ffa114 25.71%, #f2d518 50.53%, #9ee117 75.48%, #42a62e 99.44%);
    position: relative;
}

.tooltip {
    background-color: #fff;
    border: 1px solid #d8e1eb;
    border-radius: 6px;
    box-shadow: 4px 4px 0px rgba(64, 101, 128, 0.1);
    font-size: 14px;
    text-align: center;
    padding: 5px 10px;
    position: relative;
}

.text {
    flex: 1;
    text-align: center;
}

.text:first-child {
    text-align: start;
}

.text:last-child {
    text-align: end;
}

.divider {
    border-color: #fff;
}

.divider:first-child {
    border-color: transparent;
}

.divider:last-child {
    border-color: transparent;
}
