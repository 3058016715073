.stocksMarketWrapper {
    margin: 0 36px;

    .chartWrapper {
        gap: 24px;
        align-self: center;
    }

    h1 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.1px;
        text-align: left;
        color: var(--ds-main-black);
    }

    .dataContainer {
        display: grid;
        gap: 5px;
        grid-template-columns: 2fr 1fr;

        h2 {
            font-family: FavoritPro, sans-serif;
            font-size: 19px;
            font-weight: 400;
            letter-spacing: -0.01em;
            text-align: left;
            color: var(--ds-main-black);
            margin-bottom: 4px !important;
        }

        .stockIndexesWrapper {
            width: 735px;
            gap: 23px;
            margin-top: 28px;

            table thead th {
                padding-top: 1px !important;
                padding-bottom: 4px !important;
            }
        }
    }

    .tableValueMeasureWrapper {
        height: 100%;
        font-size: inherit;
        font-weight: inherit;
        color: #a5b1c0;
    }

    .chartWrapper {
        gap: 24px;
        align-self: center;
        padding-top: 10px;
    }
}

.tableNameColumn * {
    font-family: FavoritPro, sans-serif !important;
    font-size: 14px !important;
    font-weight: 300 !important;
}

.stocksIndexes {
    h2 {
        margin-bottom: 10px !important;
    }
}

.topStocksIndexes {
    h2 {
        letter-spacing: -0.3px !important;
        margin-bottom: 9px !important;
    }

    table tbody tr:nth-child(2n + 2) > td {
        height: 38px !important;

        & > span {
            margin-top: 2px;
        }
    }
}

.tableValueColumn * {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: right !important;
}

.cashFlow {
    gap: 56px;
    margin-top: 51px;
    margin-bottom: 84px;

    .firstRow,
    .secondRow {
        gap: 60px;
    }
}

.tableDataView {
    gap: 11px;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-right: 10px;
    }

    table {
        thead th {
            padding-bottom: 0px !important;
            padding-left: 4px !important;
            padding-right: 6px !important;
            padding-top: 2px !important;

            &:last-child {
                padding-left: 0 !important;
            }
        }
        tbody {
            td span {
                padding-top: 8px !important;
                padding-bottom: 8px !important;
                height: 42px !important;
            }
        }
    }
}

.fallLeader {
    &_arrow {
        rotate: 180deg;

        path {
            fill: #f2433d;
        }
    }

    &_table {
        width: 496px !important;
        grid-template-columns: minmax(200px, 293px) 115px minmax(88px, auto) !important;
    }

    &_change {
        width: 100%;
        justify-content: flex-end;
    }
}

.topByVolWrapper {
    width: 735px;
    margin-top: -2px;

    h2 {
        letter-spacing: -0.3px;
    }

    & > div > div {
        gap: 13px !important;
    }
}

.noTextOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
