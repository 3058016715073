.table {
    width: 100%;

    &_row {
        padding-top: 1px;
        padding-bottom: 1px;

        & > div {
            width: 100%;

            & > span {
                flex-grow: 1;
                width: 50%;
                height: 42px !important;
                padding-left: 18px;
                padding-top: 12px;
                padding-bottom: 12px;

                &:first-child {
                    font-family: FavoritPro, sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 18px;
                    color: #576273;
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                    font-family: Inter, sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                }
            }
        }

        border-bottom: 1px solid #d8e1eb;

        &:nth-child(2n + 1) > div {
            background-color: rgba(215, 230, 245, 0.2);
        }
    }
}
