.cashFlowWrapper {
    margin: 46px 36px 92px 36px;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        letter-spacing: -0.01em;
        text-align: left;
        margin-bottom: 17px;
        color: var(--ds-main-black);
    }

    .dataContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 36px;
        column-gap: 50px;

        & table {
            width: 576px;
        }

        .tableNameColumn span {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 300 !important;
            text-align: left;
            color: var(--ds-main-black);
        }

        .tableValueColumn * {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: var(--ds-main-black);
        }

        .measureWrapper {
            height: 100%;
            color: #a5b1c0;
        }
    }
}
