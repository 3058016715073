.loader {
    height: calc(var(--loader-size) * 0.6);
    width: var(--loader-size);
    display: flex;
    justify-content: center;
}

.bar {
    width: calc(var(--loader-size) * 0.16);
    height: calc(var(--loader-size) * 0.6);
    background-color: #636e80;
    border-radius: 2px;
    margin-right: calc(var(--loader-size) * 0.12);
}

.bar:nth-child(1) {
    animation: shrinkAndExpand1 2s linear infinite;
}

.bar:nth-child(2) {
    animation: shrinkAndExpand2 2s linear infinite;
}

.bar:nth-child(3) {
    animation: shrinkAndExpand3 2s linear infinite;
}

.bar:nth-child(4) {
    animation: shrinkAndExpand4 2s linear infinite;
}

@keyframes shrinkAndExpand1 {
    0% {
        transform: scaleY(1);
    }
    20.1% {
        transform: scaleY(0.2);
    }

    80.9% {
        transform: scaleY(0.2);
    }
    100% {
        transform: scaleY(1);
    }
}
@keyframes shrinkAndExpand2 {
    0% {
        transform: scaleY(1);
    }
    22.2% {
        transform: scaleY(1);
    }
    33.3% {
        transform: scaleY(0.2);
    }
    77.8% {
        transform: scaleY(0.2);
    }
    89.9% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes shrinkAndExpand3 {
    0% {
        transform: scaleY(1);
    }
    33.3% {
        transform: scaleY(1);
    }
    44.4% {
        transform: scaleY(0.2);
    }
    66.6% {
        transform: scaleY(0.2);
    }
    77.7% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes shrinkAndExpand4 {
    0% {
        transform: scaleY(1);
    }
    44.4% {
        transform: scaleY(1);
    }
    55.5% {
        transform: scaleY(0.2);
    }
    66.6% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(1);
    }
}
