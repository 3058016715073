.createFavoritesListModalWrapper {
    width: 514px;
    padding: 48px;
    gap: 30px;
}

.contentWrapper {
    gap: 20px;

    & > h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);
    }

    & > input {
        height: 36px;
        padding: 8px 12px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        color: var(--ds-dtsip-text-black-100);

        &::placeholder {
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-dtsip-text-light-25);
        }
    }
}

.btnWrapper {
    gap: 26px;

    & > button {
        width: 196px;
        height: 48px;
        padding: 16px 10px;
        border: none;
        border-radius: 6px;

        & > span {
            font-family: FavoritPro, sans-serif;
            font-size: 13px;
            font-weight: 350;
            line-height: 16px;
            text-align: center;
            color: var(--ds-main-white);
        }

        &.cancelBtn {
            background: #a5b1c0;
        }

        &.confirmBtn {
            background: var(--ds-bg-dark-blue);
        }
    }
}
