.list {
    height: 82px;
    min-height: 82px;
    max-height: 82px;
    border-radius: 12px;
    border: 1px solid var(--ds-border-gray);
    margin-bottom: 8px;
    padding: 16px 20px 16px 22px;
    cursor: pointer;

    &_title {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #14171f;
        margin-bottom: 12px;
    }

    &_subtitle {
        font-size: 14px;
        font-weight: 350;
        line-height: 14px;
        color: var(--ds-dtsip-text-light-25);
    }

    &__checked {
        border: 1px solid var(--ds-main-blue);
        position: relative;

        &::before {
            content: '';
            background-color: var(--ds-main-blue);
            border-radius: 10px;
            opacity: 0.1;
            position: absolute;
            top: 4px;
            left: 4px;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
        }
    }
}

.checkbox {
    & > div:last-child {
        border-width: 1px;
        border-radius: 4px;
    }
    &__checked > div:last-child {
        background: #2f9ceb;
        border-color: #2f9ceb;
    }
}

.emptyData {
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    text-align: left;
    color: #576273;
}

.isFavouriteIcon {
    cursor: pointer;
}

.modalWindow {
    height: 619px;
    width: 564px;

    &_wrapper {
        padding: 48px 32px 48px 48px;
        height: 100%;
    }

    &_title {
        position: relative;
        font-size: 24px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: var(--ds-main-black);

        padding-bottom: 33px;
    }

    & > :first-child > div:first-child {
        padding: 48px 48px 32px;
        font-size: 24px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
    }

    &_watchlist {
        height: 278px;
    }

    &_listWrapper {
        height: calc(100% - 120px);
        max-height: calc(100% - 120px);
        overflow-y: auto;
        margin-bottom: auto;
        padding-right: 16px;
    }
}

.actionsWrapper {
    margin-top: auto;
    gap: 0 24px;

    & > button {
        width: 100%;
        height: 50px;
        border: none;

        &:first-child {
            background: #a5b1c0;
        }

        & > span {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;

            color: var(--ds-main-white);
        }
    }
}

.input {
    width: 100%;
    height: 50px;
    padding: 15px 24px;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}
