@import '../../../../../../shared/commonStyles/main-styles.scss';

.currency {
    margin-left: 4px;

    font-family: Inter, sans-serif;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: var(--ds-dtsip-text-light-25);
}

.number {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;

    &__positive {
        color: green;
    }

    &__negative {
        color: red;
    }

    &__neitral {
        color: #14171f;
    }
}

$blue-color: #2f9ceb;
.primary {
    cursor: pointer;

    &:hover {
        text-decoration-line: underline;
        text-decoration-color: $blue-color;
        text-decoration-style: dashed;
        text-underline-offset: 3px;
        color: $blue-color;
    }
}

.sortIcon {
    margin-top: 5px;
}

[aria-sort='ascending'] .sortIcon {
    & > path:last-child {
        stroke: var(--ds-bg-dark-blue);
    }
}

[aria-sort='descending'] .sortIcon {
    & > path:first-child {
        stroke: var(--ds-bg-dark-blue);
    }
}

.table {
    min-height: 570px;

    [data-pc-section='wrapper'] {
        @include scrollbar;
    }

    &_loadingOverlay {
        background-color: transparent;
    }

    & tr {
        height: 42px;

        &:nth-child(odd) {
            background: #f7fafd;
        }

        &:nth-child(even) {
            background: var(--ds-main-white);
        }
    }

    & th {
        border: none;
        border-right: 2px solid var(--ds-main-white);
        position: relative;

        background-color: transparent;
        border-bottom: none;
        height: 32px;
        margin: 0;
        padding: 0 18px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 4;
            height: 1px;
            background-color: #636e80;
        }

        & > div > span:not([data-pc-section='sort']) {
            width: 100%;
            height: 100%;
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 16px;
            letter-spacing: 0;

            white-space: nowrap;
            color: var(--ds-dtsip-text-light-25);

            &:last-child {
                display: flex;
            }
        }
    }

    td {
        border-right: 2px solid var(--ds-main-white);
        border-bottom: none;
        border-top: none;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 4;
            height: 1px;
            background-color: #d8e1eb;
        }

        & > div {
            width: 100%;
            height: 100%;
            padding: 0 18px;

            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
            white-space: nowrap;
        }
    }

    [data-p-frozen-column='true'] {
        position: sticky !important;
        background: inherit;
        z-index: 1;
    }
}

.isFavouriteColumn {
    max-width: 36px;
    width: 36px;
    padding: 0 9px;

    &__disabled {
        & span {
            cursor: default;

            &:hover {
                color: #a5b1c0;
            }
        }
    }
}

.customPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    span {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            background: rgba(216, 225, 235, 0.2);
            border: 1px solid #d8e1eb;
            width: 36px;
            height: 36px;
            padding: 0;
            margin: 0;
            border-radius: 4px;
            min-width: 36px;
            max-width: 36px;
        }
        button {
            min-width: 36px;
            max-width: 36px;
            height: 36px;
            background: rgba(216, 225, 235, 0.2);
            border: 1px solid #d8e1eb;
            border-radius: 4px;
            padding: 0;
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }

    button {
        height: 36px;
        text-align: center;
        background: rgba(216, 225, 235, 0.2);
        border: 1px solid #d8e1eb;
        border-radius: 4px;
        padding: 0;
        margin: 0;

        span {
            color: rgba(138, 150, 166, 1);
            text-align: center;
            padding: 6px 20px;
        }
    }

    .currentPage {
        background: rgba(99, 110, 128, 1);
        color: rgba(255, 255, 255, 1);
        border: 1px solid transparent;
    }
}
