.emptyDataWrapper {
    gap: 20px;

    & > svg {
        width: 120px;
        height: 88px;
    }

    & > div {
        gap: 8px;

        & > h2 {
            font-family: FavoritPro, sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--ds-dtsip-text-black-100);
        }

        & > p {
            font-family: FavoritPro, sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            text-align: center;
            color: var(--ds-dtsip-text-black-100);
        }
    }
}
