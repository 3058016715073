.star {
    &__active {
        width: 16px;
        height: 16px;

        path {
            fill: rgba(47, 156, 235, 1) !important;
        }
    }

    &__hovered {
        width: 16px;
        height: 16px;
        transform: translateY(-0.2px);
    }

    &__notActive {
        width: 16px;
        height: 16px;
    }
}
