.heatMapContainer {
    .titleWrapper {
        margin-bottom: 20px;

        h2 {
            font-family: FavoritPro, sans-serif;
            font-size: 19px;
            font-weight: 400;
            color: var(--ds-main-black);
            text-align: left;
            letter-spacing: -0.4px;
        }
    }

    .controls {
        gap: 14px;
    }

    .heatMapColorMarkersWrapper {
        margin-top: 8px;

        & > span {
            margin-left: 2px;
            font-family: Inter, sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 40px;
            text-align: right;
            color: var(--ds-dtsip-text-light-25);
        }

        & > div {
            gap: 2px;
        }

        .heatMapColorMarker {
            height: 30px;
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: var(--ds-main-white);

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.colorRange {
    gap: 2px;
}

.colorRangeTooltip {
    &_wrapper {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;

        &__hidden {
            opacity: 0;
            pointer-events: none;
            user-select: none;
        }
    }

    &_mark {
        padding: 9px 18px 13px 18px;
        border-radius: 6px;
        text-wrap: nowrap;
        flex-wrap: nowrap;
        width: auto;
        height: 41.5px;
        color: var(--ds-main-white);
        background: #636e80;
        position: absolute;
        top: -6px;
        transform: translateY(-100%) translateX(-50%);
    }

    &_arrow {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
    }
}

.tooltip {
    & > div:first-child {
        font-family: FavoritPro, sans-serif;
        font-size: 16px;
        font-weight: 350;
        text-align: left;
        color: var(--ds-main-black);
    }

    &_profitWrapper {
        gap: 5px;
    }

    &_price {
        font-family: Inter, sans-serif !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        color: var(--ds-main-black) !important;
    }
}

.heatMapWrapper {
    width: 100%;
    height: 620px;
    position: relative;
    border: 1px solid transparent;

    &__noData {
        border-color: #8a96a6;
        border-radius: 20px;
    }

    &_emptyTitle {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        color: #8a96a6;
        text-align: left;
        letter-spacing: -0.4px;
    }
}
