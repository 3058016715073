.tooltip {
    width: 230px !important;

    &_navigationButton {
        gap: 10px;

        & > div {
            transition: background-color 0.2s;
            background-color: transparent !important;
            padding-left: 1px;
        }

        &:hover > div {
            background-color: #d8e1eb !important;
        }
    }

    &_title {
        font-family: FavoritPro, sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 18px;
        text-align: left;
    }
}
