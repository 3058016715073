.tradesResultsChart {
    width: 1215px;

    & > div > div > div {
        transform: translateX(-38px) translateY(2px);
    }

    & > div > div:last-child {
        transform: translateX(-25px);
    }
}
