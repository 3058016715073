.tabView {
    margin: 0 36px 0;
    height: calc(100% - 24px);
    min-height: 800px;

    & > div,
    & > div ul {
        background: transparent;
    }

    & > div:first-child > div ul li a {
        border-radius: 0;
    }

    & > div:first-child > div ul li a[aria-selected='false'] {
        font-family: FavoritPro, sans-serif;
        font-size: 15px;
        font-weight: 350;
        line-height: 16px;
        text-align: left;
        transition: 0.3s;

        & span {
            font-size: 15px;
            line-height: 16px;
            text-align: left;
            line-height: 16px;
            letter-spacing: 0.1px;
            margin-top: 1px;

            color: var(--ds-dtsip-text-light-25);
        }
    }

    & ul {
        gap: 28px;
        border-bottom-width: 1px;

        & li:last-child {
            height: 3px;
            background-color: var(--ds-main-red);
        }
    }

    & a {
        padding-bottom: 14px;
        border: none;
        background: transparent;
    }

    & th {
        padding: 35px 0 10px 20px;

        font-size: 14px;
        font-weight: 350;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        background-color: transparent;

        &:last-child {
            padding: 35px 12px 12px;
        }

        & span:last-child {
            margin-left: auto;
        }
    }

    & > div:last-child {
        margin-top: 56px;
    }
}

.tabViewNavContent {
    margin: 24px 32px 0;

    [aria-selected='false'] {
        font-weight: 350 !important;
    }

    [aria-selected='true'] {
        color: var(--ds-dtsip-text-black-100);
        font-weight: 500 !important;
    }
}
