.tablesWrapper {
    display: flex;
    gap: 60px;

    .table {
        width: 100%;

        .labelColumn span {
            color: #576273;
            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
        }

        .valueColumn * {
            font-family: Inter, sans-serif !important;
            font-size: 15px !important;
            font-weight: 400 !important;
        }
    }
}
