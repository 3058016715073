.tabLabel {
    font-size: 15px;

    & > span:first-child {
        letter-spacing: 0 !important;
    }

    &.hasUnreadAlerts > span {
        color: black !important;
        font-weight: 500;
    }
}

.tabView {
    height: 100%;

    &_navContent {
        margin: 35px 0 0;

        & > ul {
            gap: 28px;
            border-bottom-width: 1px;

            & > li > a {
                border-radius: 0;
                padding-bottom: 14px;
                border: none;
                background: transparent;
            }

            & li:last-child {
                height: 3px;
                background-color: var(--ds-main-red);
            }
        }

        [aria-selected='false'] {
            & span {
                font-weight: 350 !important;
                font-size: 15px;
                line-height: 16px;
                text-align: left;
                letter-spacing: -0.02em;

                color: var(--ds-dtsip-text-light-25);
            }
        }

        [aria-selected='true'] {
            color: var(--ds-dtsip-text-black-100);
        }
    }

    &_panelContainer {
        height: calc(100% - 47px);
        margin-top: 16px;

        & > div {
            height: 100%;
        }
    }
}

.countAlerts {
    background-color: #b7cee533;
    color: var(--ds-dtsip-text-light-25);
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    margin-left: 6px;
    font-family: Inter, sans-serif;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500;
}

.alertName {
    font-family: FavoritPro, sans-serif;
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    z-index: 0;

    .alertTicker {
        font-family: FavoritPro, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-align: left;
        color: #636e80;
    }

    color: #14171f;
}

.alertPrice {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    z-index: 0;
    color: #14171f;

    .priceMeasure {
        font-family: Inter, sans-serif !important;
        font-size: 11px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        text-align: right !important;
        color: var(--ds-dtsip-text-light-25) !important;
    }
}

.fullfilledAlertsTab {
    table tr td > span {
        & > div[data-read='true'],
        & > span[data-read='true'] {
            color: var(--ds-dtsip-text-light-25) !important;

            & > * {
                color: var(--ds-dtsip-text-light-25) !important;
            }
        }
    }
}

.alertsSidebar {
    width: 570px;
    position: relative;
    border-top: 1px solid #d8e1eb;
    border-left: 1px solid #d8e1eb;
    box-shadow: none;

    &_header {
        margin: 28px 30px 0;
    }

    & > div {
        &:first-child {
            margin-left: 48px;
            margin-right: 44px;
            margin-top: 28px;
        }

        &:last-child > div {
            overflow: visible;
            margin-left: 48px;
            margin-right: 48px;
            margin-bottom: 43px;
            height: calc(100% - 160px);
            margin-top: 25px;
        }

        th,
        td {
            &:last-child {
                width: 14px;
                background: none;
            }
        }

        [data-pc-section='bodycell'] > span {
            height: 55px;
        }

        th:not(:last-child) > [data-pc-section='headercontent'] {
            padding: 13px 18px !important;
        }
    }

    &Wrapper {
        top: 116px !important;
        height: calc(100vh - 116px);

        &.securitiesPage {
            width: 483px !important;
            left: calc(100vw - 483px) !important;
        }
    }

    &HeaderWrapper {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);

        & > button {
            width: 1rem;
            background: transparent;
            border: none;
            cursor: pointer;
        }
    }
}

.alertsSidebarWrapper {
    top: 116px !important;
    height: calc(100vh - 116px);

    &.securitiesPage {
        width: 483px !important;
        left: calc(100vw - 483px) !important;
    }
}
