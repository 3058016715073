.root:not(:last-child) {
    margin-bottom: 16px;
}

.body {
    align-items: center;
}

.label {
    padding-inline-start: 15px;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
}

.input {
    border: 1px solid #a5b1c0;
}

.input:hover {
    border: 1px solid #636e80;
}

.input:active,
.input:checked:hover {
    border: 1px solid #2f9ceb;
}

.input:disabled {
    border: 1px solid #d8e1eb;
    background: #d8e1eb4d;
}
