.bondsMarketWrapper {
    margin: 0 36px;

    h1 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.1px;
        text-align: left;
        color: var(--ds-main-black);
    }

    .dataContainer {
        h2 {
            font-family: FavoritPro, sans-serif;
            font-size: 19px;
            font-weight: 400;
            letter-spacing: -0.01em;
            text-align: left;
            color: var(--ds-main-black);
            margin-bottom: 12px;
        }

        .bondIndexesWrapper {
            width: 578px;
            margin-top: 32px;
            gap: 21px;

            table th {
                padding-bottom: 4px !important;
            }

            h2 {
                margin-bottom: 2px;
            }

            h2:last-child {
                margin-bottom: 8px;
            }
        }
    }

    .tableValueMeasureWrapper {
        height: 100%;
        font-size: inherit;
        font-weight: inherit;
        color: #a5b1c0;
    }
}

.noTextOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
