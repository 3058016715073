.footerWrapper {
    padding: 24px 0;
    border-top: 1px solid var(--ds-border-gray);
    margin: 36px 36px 30px;
    background: linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(180deg, rgba(217, 230, 244, 0) 0%, rgba(217, 230, 244, 0.6) 100%);
}

.footerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
}

.headerInfo {
    display: flex;
    align-items: center;
    gap: 36px;

    a {
        text-decoration-color: var(--ds-main-blue);

        p {
            font-size: 14px;
            font-weight: 350;
            line-height: 24px;
            letter-spacing: 0.35px;
            text-align: left;
            color: var(--ds-main-blue);
        }
    }
}

.socialWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    a {
        display: block;

        & > button {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
        }
    }
}

.logoWrapper {
    padding-bottom: 18px;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: 0.3px;
        text-align: left;
        color: var(--ds-main-gray);
    }
}

a.disabled {
    pointer-events: none;
}

@media (max-width: 1359px) {
    .footerWrapper {
        padding: 22px 24px;
    }

    .footerHeader {
        align-items: baseline;
    }

    .headerInfo {
        flex-direction: column;
        align-items: baseline;
        gap: 10px;

        a p {
            font-size: 13px;
            line-height: 14px;
            letter-spacing: 0.3px;
        }
    }

    .socialWrapper {
        a button {
            width: 32px;
            height: 32px;
        }
    }

    .logoWrapper {
        padding-bottom: 16px;
    }

    .infoWrapper {
        gap: 9px;

        p {
            font-size: 13px;
            letter-spacing: 0.25px;
        }
    }
}

@media (max-width: 767px) {
    .footerWrapper {
        padding: 46px 18px;
    }

    .footerHeader {
        flex-direction: column;
        gap: 21px;
        margin-bottom: 50px;
    }

    .headerInfo {
        gap: 11px;
    }

    .logoWrapper {
        padding-bottom: 10px;

        svg {
            width: 116px;
            height: 36px;
        }
    }
}
