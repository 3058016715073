.root {
    background-color: transparent;
    color: #a5b1c0;
    border: none;

    &[data-variant='dark'] {
        color: #14171f;
    }

    &[data-variant='outline'] {
        font-size: 13px;
        line-height: 16px;
        font-weight: 350;
        background: #fff;
        border: 1px solid #d8e1eb;
    }

    &[data-variant='outline']:hover {
        background: #eaf5fd;
        border: 1px solid #2f9ceb;
    }

    &[data-variant='outline']:active {
        background: #eaf5fd;
        border: 1px solid #2f9ceb;
        transform: none;
    }

    &[data-variant='outline']:disabled {
        background: rgba(216, 225, 235, 0.3);
        border: 1px solid #d8e1eb;
        opacity: 0.4;
    }
}

.root:hover {
    background-color: transparent;
    color: #636e80;
}

.root:active {
    transform: none;
    background-color: transparent;
    color: #636e80;
}

.root:disabled {
    background-color: transparent;
    color: #d8e1eb;
}

.icon {
    color: inherit;
}
