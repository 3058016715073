.tabNavigationButtonWrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;

    .navigationButtonPoint {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: var(--ds-main-light-gray);

        svg {
            width: 12px;
            height: 12px;
            transform: rotate(180deg);
        }
    }
}
