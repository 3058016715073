.customPanel {
    top: 237px !important;
    padding: 0;

    [class~='p-focus'] {
        background: transparent;
    }
}

.customItemGroup {
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
}

.customItem {
    width: 100%;
    padding: 6px 12px 4px;

    &:hover {
        background: #ecf5ff;
    }

    &:first-child {
        padding-top: 10px;
    }

    &:last-child {
        padding-bottom: 10px;
    }
}

.customItemLabel {
    width: 100%;
}

.watchlistItemWrapper {
    &_content {
        gap: 11px;
    }

    &_tools {
        gap: 2px;
        display: flex;
        opacity: 0;

        & > button {
            width: 32px;
            height: 32px;
            border: none;
            border-radius: 4px;
            background-color: #ecf5ff;
            padding: 6px;
        }
    }

    &:hover &_tools,
    [aria-selected='true'] &_tools {
        opacity: 1;
    }
}

.titleWrapper {
    height: 36px;

    &_title {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);
    }

    &_count {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: #636e80;
    }
}

.radioButtonWrapper {
    width: 16px;
    height: 16px;

    &_box {
        width: 16px;
        height: 16px;
        border: 1px solid #a5b1c0;
    }

    &_icon {
        width: 10px;
        height: 10px;
        background: var(--ds-bg-dark-blue);
    }

    &[data-p-checked='true'] .radioButtonWrapper_box {
        border-color: var(--ds-bg-dark-blue);
        background: var(--ds-main-white);
    }
}
