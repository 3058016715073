html {
    font-size: 16px;
    background-color: white;

    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'FavoritPro', sans-serif;
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    color: var(--ds-main-black);
}

:is(input, button):focus {
    box-shadow: none;
}

.p-tooltip {
    border-radius: 8px;
}

.p-tooltip-text {
    position: relative;
    top: -16px;
    background: var(--ds-main-white);
    color: var(--ds-main-black);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
}

.p-tooltip-arrow {
    z-index: 10000;
    bottom: 12px !important;
    border-top-color: var(--ds-main-white);
    margin-left: -0.5rem;
    border-width: 0.25em 0.5em 0;
}

a.disabled {
    pointer-events: none;
}

.p-autocomplete-items-wrapper {
    max-height: none !important;
    height: auto;
}

.ql-tooltip.ql-hidden {
    display: none;
}

.p-dropdown-label:is(.p-inputtext:hover, .p-inputtext:focus-visible) {
    border: none;
}

.p-dropdown {
    border: 1px solid #d1d5db;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
}

.p-progress-spinner-circle {
    stroke: rgb(0, 162, 255) !important;
}

.main-wrapper {
    display: flex;
    justify-content: center;

    & > :first-child {
        max-width: 1930px;
        width: 100%;
    }
}

@mixin minContentWidth {
    min-width: calc(1920px - var(--scrollbar-width));
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        border-radius: 10px;
        height: 4px;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--ds-bg-light-gray);
        height: 90%;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--ds-main-gray);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background-color: var(--ds-main-black);
        }
    }
}

body {
    background-color: var(--ds-main-white);
    @include scrollbar;
}
