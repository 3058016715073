.closeIcon {
    position: absolute;
    top: -4px;
    right: -4px;
    cursor: pointer;
    z-index: 1;
}

.isMobile {
    width: 100vw !important;
    height: 100vh !important;
}

.modal {
    border-radius: 6px;
    border: 1px solid #d8dfe6;

    & > * {
        border-radius: 6px;
    }
}
