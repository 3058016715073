.investor-pro-custom-select-button {
    display: flex;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    background-color: var(--ds-main-white);

    .p-button {
        padding: 6px 18px !important;
        background-color: transparent;
        border: none;
        border-radius: 6px;
        transition: 0ms;

        &:not([aria-pressed='true']) {
            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 50%;
                    transform: translate(0.5px, 50%);
                    right: 0;
                    width: 1px;
                    height: 13px;
                    background-color: #a5b1c0;
                }
            }
        }

        .p-button-label {
            font-family: 'FavoritPro', sans-serif;
            font-size: 15px;
            font-weight: 350;
            color: var(--ds-main-black);
        }

        &.p-highlight {
            background-color: var(--ds-main-black);

            .p-button-label {
                color: var(--ds-main-white);
            }
        }

        &.p-focus {
            box-shadow: none;
        }
    }
}
