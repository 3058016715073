.baseInformationTabWrapper {
    display: flex;
    justify-content: space-between;
    gap: 59px;
    height: auto;
    width: 100%;

    .infoColumns {
        display: flex;
        flex-direction: column;
        width: 1230px;

        &:first-child {
            gap: 54px;
        }

        &:last-child {
            gap: 60px;
        }

        h2 {
            font-family: FavoritPro, sans-serif;
            font-size: 19px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.01em;
            text-align: left;
            color: var(--ds-main-black);
        }

        .aboutCompany,
        .financials,
        .chart,
        .income,
        .news {
            display: flex;
            flex-direction: column;
        }

        .chart h2 {
            margin-bottom: 6px;
        }

        .financials {
            gap: 24px;
        }

        .income h2 {
            margin-bottom: 4px;
        }

        .chart {
            margin-left: 1px;
        }

        .aboutCompany {
            & > div {
                margin-bottom: 32px;
            }
        }
    }
}

.income {
    table {
        width: 1214px;

        th {
            padding-right: 6px !important;
        }

        td span {
            height: 42px !important;
        }
    }
}

.aboutInfo {
    column-gap: 32px;
    width: 480px;
}

.chart {
    &_wrapper {
        margin-left: -25px;
    }
}

.yield {
    &_value {
        font-family: Inter, sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    &_title > span {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
    }
}
