.valueWithMeasureWrapper {
    display: inline;
    padding: 0;
    margin: 0;

    .measure {
        font-family: FavoritPro, sans-serif;
        font-size: 8px;
        font-weight: 350;
        line-height: 18px;
        text-align: right;
        text-transform: uppercase;
    }
}
