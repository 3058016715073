.tooltip {
    gap: 4px;
    width: auto;

    &_header {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 16px;
        text-align: left;
        color: var(--ds-main-black);
        margin-bottom: 4px;
    }

    &_divider {
        height: 1px;
        width: 100%;
        background-color: var(--blue-100);
    }

    &_content {
        gap: 4px;

        & > div {
            gap: 10px;
        }

        span {
            &:first-child {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
                color: var(--ds-main-gray);
            }

            &:last-child {
                font-family: Inter, sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: right;
                color: var(--ds-main-black);
                text-wrap: nowrap;
            }
        }
    }
}
