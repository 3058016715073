.p-datatable.investor-pro-custom-table {
    table {
        table-layout: fixed !important;
    }

    th {
        padding-bottom: 13px;
        border-bottom: none;
        padding: 0;
        background-color: transparent;
        position: relative;

        .p-column-title {
            font-family: 'FavoritPro', sans-serif;
            font-weight: 350;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0;
            text-align: left;
            color: var(--ds-dtsip-text-light-25) !important;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            z-index: 4;
            height: 1px;
            background-color: #636e80;
        }

        & > div {
            margin-left: 0;
            margin-bottom: 1px;
            padding: 13px;

            & > span {
                margin-left: 0;
            }
        }

        &.p-align-right span {
            text-align: right !important;
        }

        &.p-align-center span {
            text-align: center !important;
        }

        &.p-align-left span {
            text-align: left !important;
        }
    }

    tr:first-child {
        td {
            padding-top: 1px;
        }
    }

    &:not(.empty-table) td {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #d8e1eb;
        }
    }

    td {
        position: relative;
        padding: 1px 0 2px 0;
        background-color: transparent;
        border-bottom: none;
        overflow: hidden;

        &.p-align-right > span {
            justify-content: flex-end;
        }

        &.p-align-center > span {
            justify-content: center;
        }

        &.p-align-left > span {
            justify-content: flex-start;
        }
    }

    tr:nth-child(2n + 1) > td {
        background-color: none !important;

        & > span {
            background-color: rgba(215, 230, 245, 0.2);
        }
    }

    td {
        &:last-child {
            border-right: none;
        }
    }

    &.separate-columns td,
    th {
        border-right: 2px solid white;
    }

    &.without-headers tr:first-child td::before {
        background-color: transparent;
    }
}

.investor-pro-custom-table-overlaylPanel::before,
.investor-pro-custom-table-overlaylPanel::after {
    display: none !important;
}
