@import '../../../../shared/commonStyles/media-css-variables';

.footerWrapper {
    width: 100%;
    padding: 42px 62px 48px 62px;
    background: var(--ds-main-black);
}

.footerContent {
    display: grid;
    width: 100%;
    max-width: 1920px;
    grid-template-areas:
        'links social'
        'logo logo'
        'info support';
    grid-template-columns: 1fr 329px;
    grid-template-rows: 47px 42px 1fr;
    gap: 23px;
}

.footerHeader {
    grid-area: links;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: auto;
}

.headerInfo {
    display: flex;
    align-items: center;
    gap: 36px;

    a {
        text-underline-offset: 4px;
        text-decoration-color: var(--ds-main-gray);

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.02em;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

.socialWrapper {
    grid-area: social;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: auto;

    &_icon {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 100%;
        background: var(--ds-main-white);

        &__centered > svg {
            margin-right: 2px;
        }
    }
}

.logoWrapper {
    grid-area: logo;

    //margin-bottom: 20px;
}

.infoWrapper {
    grid-area: info;

    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        color: var(--ds-main-gray);
    }
}

.supportWrapper {
    grid-area: support;

    display: flex;
    margin-top: 11px;
    align-items: center;
}

.support {
    &_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #868e96;
        background-color: #495057;
        display: flex;
        justify-content: center;

        margin-right: 16px;

        & > svg {
            margin-top: 11px;
        }
    }

    &_call,
    &_schedule {
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    &_call {
        font-weight: 400;
        line-height: 24px;
        color: var(--ds-main-white);
    }

    &_schedule {
        font-weight: 350;
        line-height: 18px;
        color: var(--ds-main-gray);
    }
}

a.disabled {
    pointer-events: none;
}
