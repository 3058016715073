.wrapper {
    position: relative;
    max-width: 640px;
    width: 100%;
}

.searchWrapper {
    position: relative;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > svg {
        top: 9px;
    }
}

.menuWrapper {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0px;
    z-index: 100;

    &__content {
        background-color: white;
        box-shadow: 4px 4px 0px 0px #4065801a;
        border: 1px solid #d8e1eb;
        height: 567px;
        overflow: auto;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    &__loader {
        background-color: white;
        box-shadow: 4px 4px 0px 0px #4065801a;
        border: 1px solid #d8e1eb;
        height: 567px;
        overflow: auto;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

.isModal {
    &.searchWrapper {
        & > input {
            width: 630px;
            height: 44px;

            font-family: FavoritPro, sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-dtsip-text-light-25);

            &::placeholder {
                font-family: FavoritPro, sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 20px;
                text-align: left;
                color: var(--ds-dtsip-text-light-25);
            }
        }

        & > button {
            top: 10px;
        }
    }

    &.menuWrapper {
        position: static;
        margin-top: 30px;

        & > div {
            box-shadow: none;
            height: auto;
            max-height: 375px;
        }
    }

    &.empty {
        height: auto;
    }

    [data-pc-section='content'] > div > div:last-child {
        & > p {
            width: 103px;
        }

        & > div:last-child {
            padding: 7px;
        }
    }
}

.clearButton {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    right: 6px;
    top: 6px;
    width: 24px;
    height: 24px;

    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.searchInput {
    width: 100%;
    padding: 6px 6px 6px 42px;
}

.tabView {
    & > div {
        & > div {
            li a {
                border: none;
            }
        }
    }
}

.empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 500px;
    width: 100%;
    padding: 30px 23px;

    &__warning {
        display: block;
        font-size: 40px;
        margin-bottom: 8px;
    }

    &__warningText {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }
}
