.trades {
    width: 419px;

    table {
        width: 419px;

        span {
            font-family: Inter, sans-serif;
        }
    }

    .green {
        color: green;
    }

    .red {
        color: red;
    }
}
