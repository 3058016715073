.tagsWrapper {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0 12px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: var(--ds-main-gray);
  }
}

@media (max-width: 1359px) {
  .tagsWrapper {
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
