.empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 485px;
    width: 100%;
    padding: 30px 23px;

    &.isModal {
        height: 260px;
    }

    &_warning {
        display: block;
        font-size: 40px;
        margin-top: 12px;
        margin-bottom: 12px;
        color: #a5b1c0;
    }

    &_text {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #14171f;

        text-align: center;
    }
}
