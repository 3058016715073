.indexesWrapper {
    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 19px;
        font-weight: 400;
        letter-spacing: -0.1px;
        text-align: left;
        color: var(--ds-main-black);
        margin-bottom: 10px;
    }
}

.noTextOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
