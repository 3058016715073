.addInstrumentModalWrapper {
    width: 726px;
    height: 541px;
    padding: 48px;
    gap: 19px;
    overflow: hidden;

    & > h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--ds-dtsip-text-black-100);
    }
}
