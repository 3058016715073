.dragHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.dragHandle:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.dragHandle:focus-visible {
    box-shadow: 0 0 0 2px #4c9ffe;
}
