$blue-10: #d5eaff;
$blue: #2f9ceb;

$black-10: #d8e1eb;
$gray: #a5b1c0;

.newScreenerModal {
    height: 100%;
    display: block;
    overflow: hidden;
}

.selectedWrapper {
    width: 826px;
    min-width: 826px;
}

.createScreenerWindow {
    width: 100vw;
    height: 100vh;
    max-width: 1394px;
    max-height: 846px;
}

.headerWrapper {
    gap: 8px;
    height: 36px;
    margin: 38px 48px 0;
}

.screenerName {
    font-size: 24px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0;
    border: none;
    color: var(--ds-main-black);

    &_title {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0;
        white-space: nowrap;
    }

    &_input {
        padding: 8px 12px 8px 12px;
        gap: 378px;
        border-radius: 6px;
        border: 1px solid #a5b1c0;
        color: #14171f;
        width: calc(100% - 300px);

        &__disabled {
            font-size: 24px;
            font-weight: 300;
            line-height: 36px;
            color: var(--ds-main-black);
            white-space: nowrap;
            max-width: calc(100% - 390px);
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }

    &_actions {
        gap: 8px;
    }

    &_actionButton {
        width: 36px;
        min-width: 36px;
        max-width: 36px;
        border-color: #d8e1eb;

        & > span {
            color: #a5b1c0;
        }

        &__save > span {
            color: #2f9ceb;
        }
    }

    &_editButton {
        width: 168px;
        height: 36px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        background-color: var(--ds-main-white);
        display: flex;
        justify-content: center;
        align-items: center;

        & span {
            font-weight: 400;
            color: var(--ds-main-black);
            font-size: 13px;
            font-family: Inter, sans-serif;
            align-items: center;
        }
    }
}

.constructorWrapper {
    position: relative;
    display: flex;
    margin-top: 24px;

    min-height: calc(100% - 300px);
    max-height: calc(100% - 300px);
    //overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 36px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #d8e1eb;
        z-index: 1;
    }
}

.toolsTypeButton {
    height: 44px;
    font-weight: 400;
    display: inline-flex;
    margin-top: 36px;
    margin-left: 48px;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    background-color: var(--ds-main-white);

    & > div {
        padding: 12px 24px;
        font-weight: 300;
        border: none;
        background-color: transparent;
        border-radius: 6px;
        position: relative;

        &:not([aria-pressed='true']) {
            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 50%;
                    transform: translate(0.5px, 50%);
                    right: 0;
                    width: 1px;
                    height: 13px;
                    background-color: #a5b1c0;
                }
            }
        }
    }

    & > div,
    span {
        font-weight: 350;
        font-size: 14px;
        line-height: 20px;
        color: #14171f;
    }

    & [class~='p-highlight'] {
        background: var(--ds-main-black);
        color: var(--ds-main-white);
        border-color: #d8e1eb;

        & > span {
            color: var(--ds-main-white);
            font-weight: 400;
        }
    }

    & [class~='p-focus']:focus {
        box-shadow: none;
    }
}

.toolsLength {
    color: var(--ds-bg-green);
}

.cancelButton {
    width: 196px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a5b1c0;
    border: none;
    & span {
        color: var(--db-main-white);
        font-weight: 500;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }
}

.createButton {
    width: 196px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ds-bg-dark-blue);
    & span {
        color: var(--db-main-white);
        font-weight: 500;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }
}

.wrapper {
    position: relative;
    width: 100%;
}

.searchWrapper {
    position: absolute;

    top: 65px;
    right: 48px;
    left: 48px;
    z-index: 1;

    & > .searchIcon {
        position: absolute;
        color: #a5b1c0;
        top: 20px;
        left: 4px;
    }

    & > .clearIcon {
        position: absolute;
        color: #a5b1c0;
        top: 0;
        right: 0;
        height: 44px;
        width: 44px;
        background: transparent;

        & > span {
            color: #a5b1c0;
        }
    }
}

.searchInput {
    height: 44px;
    width: 100%;
    font-size: 14px;
    font-weight: 350;
    padding: 0 26px 0 40px;
}

.toolsAddList {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.toolsAddListItem {
    margin-right: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 52px;
    border-bottom: 1px solid var(--ds-main-light-gray);
    overflow: hidden;

    & > span {
        font-size: 14px;
        font-weight: 350;
        max-width: calc(100% - 37px);
    }
}

.toolInput {
    height: 39px;
    padding: 0 12px;
    width: 100%;
    font-family: Inter, sans-serif;
    border-radius: 6px;
    font-weight: 350;
    font-size: 14px;
    color: var(--ds-main-black);
}

.calendarInput {
    height: 39px;
    width: 100%;

    & > input {
        padding: 0 12px;
    }
}

.singleSelect {
    width: 100%;
    height: 39px;

    &_item {
        padding: 8px 18px;
    }

    & > span {
        font-size: 14px;
        font-weight: 350;
        padding: 0 18px;
        display: flex;
        align-items: center;
    }
}

.calendar {
    &_buttonBar {
        padding: 8px;
    }
}

.criteriaHeaderWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px 46px 13px 50px;
}

.toolsSelectedWrapper {
    background-color: #f2f7fe;
    border-left: 1px solid var(--ds-main-light-gray);

    height: calc(100% - 36px);
    max-height: calc(100% - 36px);
}

.toolCurrencyDropdown {
    height: 39px;
    width: 82px;
    border-radius: 6px;
}

.customScroll {
    &::-webkit-scrollbar {
        height: 300px;
        padding-bottom: 10px;
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: var(--ds-bg-light-gray);
        height: 90%;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: var(--ds-main-gray);
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            background-color: var(--ds-main-black);
        }
    }
}

.toolsSelectedList {
    padding-top: 17px;
    padding-bottom: 17px;
    max-height: 100%;
    overflow-y: auto;

    & > li {
        width: 730px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 52px;
        margin: 0 auto;
        border-bottom: 1px solid var(--ds-main-light-gray);
    }
}

.toolHeaderWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;

    & span {
        font-size: 14px;
        font-weight: 350;
    }
}

.toolValueWrapper {
    display: flex;
    align-items: center;
    min-width: 334px;
    max-width: 334px;
    gap: 0.5rem;
}

.criteriaTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.criteriaResetButton {
    border: none;
    background: var(--ds-main-white);
    font-size: 14px;
    font-weight: 400;

    & span {
        color: var(--ds-main-blue);
        text-decoration: underline dashed;
        font-family: FavoritPro, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
    }
}

.tabView {
    width: 100%;
    max-width: 566px;
    height: 100%;

    & > div > div > [class~='p-tabview-nav'] {
        border: none;
        gap: 18px;

        & > [data-pc-section='inkbar'] {
            background-color: var(--ds-main-red);
        }

        & li:last-child {
            height: 3px;
        }
    }

    & [class~='p-tabview-selected'] {
        & a span {
            color: var(--ds-main-black);
            font-weight: 500;
        }
    }

    & [class~='p-tabview-panels'] {
        height: calc(100% - 146px);
        margin: 92px 2px 20px 48px;
    }

    & [data-pc-section='navcontainer'] {
        margin-left: 48px;
    }

    [data-pc-section='nextbutton'],
    [data-pc-section='prevbutton'] {
        border-radius: 0px 4px 4px 0px;
        position: absolute;
        top: 0;
        width: 22px;
        height: 32px;
        box-shadow: none;
        background-color: #dee8f2;
        color: #a5b1c0;
    }

    [data-pc-section='prevbutton'] {
        border-radius: 4px 0px 0px 4px;
    }
}

.tabViewCustom {
    padding-right: 4px;
    height: 100%;

    & > a {
        padding: 6px 0 16px;
        border: none;
    }

    & > a > span {
        font-size: 15px;
        font-weight: 400;
        color: var(--ds-dtsip-text-light-25);
    }
}

.trashButton {
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.trashIcon {
    height: 20px;
    width: 20px;
    color: var(--ds-main-gray);
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding: 33px 48px 48px;

    border-top: 1px solid var(--ds-main-light-gray);
}

.footerCount {
    font-size: 16px;
    font-weight: 350;
    color: var(--ds-dtsip-text-light-25);
}

.actionsWrapper {
    gap: 26px;
}

.searchIcon {
    position: absolute;
    height: fit-content;
    top: 50%;
    transform: translate(50%, -50%);
}

.msTrigger {
    width: 12px;
    margin-right: 13px;
}

.msRoot {
    width: 100%;
    height: 39px;
    cursor: pointer;
    align-items: center;
}

.msLabel {
    padding: 0 18px;
    font-size: 14px;
    font-weight: 350;
    color: var(--ds-main-black);
}

.msPanel {
    padding: 0;
}

.msHeader {
    padding: 15px 0;
    padding-left: 5px;
}

.msList {
    gap: 14px;
    display: flex;
    flex-direction: column;
    padding: 14px 5px;
}

.msItem {
    gap: 5px;
}

.msFilterContainer {
    height: 39px;
    margin-left: 5px;
    & input {
        height: 39px;
        padding-left: 10px;
    }
}

.msFilterIcon {
    transform: translate(50%, -50%);
    margin-right: 5px;
}

.msHeaderCheckboxIcon {
    width: 10px;
    height: 10px;
    border: 1px solid red;
}

.switch {
    width: 28px;
    height: 16px;
    border: 1px solid;
    border-radius: 8px;
    background-color: var(--ds-main-white);

    & > span {
        width: 24px;
        height: 12px;
        top: 1px;
        left: 1px;

        &::before {
            height: 12px;
            width: 12px;
            margin-top: -6px;
        }
    }

    &[aria-checked='false'] {
        border-color: $black-10;

        & > span {
            background-color: $black-10;

            &::before {
                background-color: $gray;
                left: -1px;
                right: auto;
            }
        }
    }

    &[aria-checked='true'] {
        border-color: $blue-10;

        & > span {
            background-color: $blue-10;
            &::before {
                background-color: $blue;
                left: -9px;
                right: auto;
            }
        }
    }
}

$tooltip-color: #636e80;

.tooltip_text {
    background: $tooltip-color;
    padding: 8px 18px;
    font-size: 13px;
    font-weight: 350;
    line-height: 16px;
    text-align: center;
    color: var(--ds-main-white);
}

.tooltip_arrow {
    border-top-color: $tooltip-color;
}
