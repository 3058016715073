.headerWrapper {
    background: var(--ds-main-black);
    width: 100%;
    height: 36px;
    display: flex;

    gap: 24px;
    padding: 0 21px;

    & > div {
        width: 100%;
        max-width: 1920px;
    }
}

.link {
    & > a {
        text-decoration: none;
        font-weight: 700;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--ds-main-white);
    }

    &_icon {
        width: 14px;
        height: 14px;
    }

    &_notificationCount {
        font-size: 11px;
        line-height: 11px;
        background: #e90000;
        border-radius: 9px;
        padding: 3px 6px;
        color: var(--ds-main-white);
        cursor: pointer;
    }
}
