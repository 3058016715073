.descriptionInformationTab {
    transform: translateY(-10px);
    gap: 32px !important;

    h2 {
        font-family: FavoritPro, sans-serif;
        font-size: 24px !important;
        font-weight: 400 !important;
        text-align: left;
    }
}

.textRowValue {
    font-family: FavoritPro, sans-serif !important;
}
